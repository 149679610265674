import { useNotificationsManager } from "@/composables/notification-manager";

export const useCopyToClipboard = () => {
  const { showToast } = useNotificationsManager();
  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text).then(
      () => {
        showToast({
          message: "Copied!",
          type: "is-success"
        });
      },
      () => {
        showToast({
          message: "Couldn't copy text!",
          type: "is-danger"
        });
      }
    );
  };

  return {
    copyToClipboard
  };
};
