import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4bd8bb8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "activity-log list-unstyled",
  "data-testing": "activity-log-container"
}
const _hoisted_2 = {
  class: "date",
  "data-testing": "blackout"
}
const _hoisted_3 = {
  class: "date",
  "data-testing": "blackout"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "event-text" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "has-text-right" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "card-footer" }
const _hoisted_15 = { class: "card-footer-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_Collapse = _resolveComponent("Collapse")!
  const _directive_editable = _resolveDirective("editable")!

  return (_openBlock(), _createBlock(_component_Collapse, {
    "is-content-empty": _ctx.activity.length === 0 && !_ctx.addingMessage,
    class: _normalizeClass({ updating: _ctx.isUpdating })
  }, {
    title: _withCtx(() => [
      _createTextVNode("Activity")
    ]),
    content: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_ctx.addingMessage && _ctx.usersStore.currentUser)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: _normalizeClass({ updating: _ctx.savingMessage })
            }, [
              _createElementVNode("small", _hoisted_2, _toDisplayString(_ctx.getDateString()), 1),
              _createElementVNode("small", null, _toDisplayString(_ctx.usersStore.currentUser.displayName) + ":", 1),
              _withDirectives(_createElementVNode("span", {
                "data-testing": "new-message-text",
                id: "new-message",
                ref: "new-message",
                class: "new-message event-text",
                onEdited: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.saveMessage && _ctx.saveMessage(...args)))
              }, null, 544), [
                [_directive_editable, true]
              ])
            ], 2))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activity, (event) => {
          return (_openBlock(), _createElementBlock("li", {
            tabindex: "0",
            class: _normalizeClass(["message-container", {
            updating: false
          }]),
            "data-testing": "event",
            key: event.timeStamp
          }, [
            _createElementVNode("small", _hoisted_3, _toDisplayString(event.displayDateTime), 1),
            _createElementVNode("div", null, [
              (event.userDisplayName)
                ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(event.userDisplayName) + ": ", 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_5, _toDisplayString(event.text), 1),
              (event.type === 'file-attached')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _createVNode(_component_SVGIcon, { path: _ctx.mdiPaperclip }, null, 8, ["path"]),
                    _createElementVNode("a", {
                      href: event.fileLocation,
                      class: "btn-3",
                      target: "_blank"
                    }, _toDisplayString(event.filename), 9, _hoisted_7)
                  ]))
                : _createCommentVNode("", true),
              (
                event.type === 'message' &&
                _ctx.usersStore.currentUser.id === event.userId
              )
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    "data-testing": "delete-message",
                    onClick: ($event: any) => (_ctx.removeMessage(event.id)),
                    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetMessageDeletion())),
                    class: _normalizeClass([{ warning: _ctx.messageBeingDeleted === event.id }, "delete-message btn-3 float-right"])
                  }, [
                    (_ctx.messageBeingDeleted === event.id)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_9, [
                          _createVNode(_component_SVGIcon, {
                            path: _ctx.mdiCheck,
                            type: 'is-danger'
                          }, null, 8, ["path"]),
                          _createTextVNode(" Confirm ")
                        ]))
                      : (_openBlock(), _createElementBlock("small", _hoisted_10, [
                          _createVNode(_component_SVGIcon, { path: _ctx.mdiDelete }, null, 8, ["path"]),
                          _createTextVNode(" Delete ")
                        ]))
                  ], 42, _hoisted_8))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.enableShowMore)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn-3 float-right",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleActivityLogShowMore()))
            }, [
              (_ctx.activityLogShowMore)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                    _createVNode(_component_SVGIcon, { path: _ctx.mdiChevronUp }, null, 8, ["path"]),
                    _createTextVNode(" Show fewer ")
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_13, [
                    _createVNode(_component_SVGIcon, { path: _ctx.mdiChevronDown }, null, 8, ["path"]),
                    _createTextVNode(" Show more ")
                  ]))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("footer", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("button", {
            class: "btn-3",
            type: "button",
            "data-testing": "add-message",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.allowMessage()))
          }, [
            _createVNode(_component_SVGIcon, { path: _ctx.mdiPlus }, null, 8, ["path"]),
            _createTextVNode(" Add message ")
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["is-content-empty", "class"]))
}