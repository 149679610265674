// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  LibraryFile,
  LibraryFileProduct,
  LibraryFileSection
} from "@/models/files.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import {
  FileToLinkToProduct,
  FileToLinkToSection,
  FileToUploadForMixin,
  FileToRename,
  FileToUpsertResponse,
  FileToUnlinkFromProduct,
  FileToUnlinkFromSection,
  OnlineFileToAttachToSection,
  OnlineFileToAttachToProduct
} from "@/models/api/files.model";
import { AxiosResponse } from "axios";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import { useContentStore } from "@/stores/content.store";
import { useFilesStore } from "@/stores/files.store";

const FilesMixin = {
  computed: {
    ...mapStores(
      useUIFilesStore,
      useProductsStore,
      useContentStructureStore,
      useContentStore,
      useFilesStore
    )
  },
  methods: {
    getProductIdsToUpdate(file: LibraryFile): number[] {
      const productIds = [
        ...file.sections.map((e: LibraryFileSection) => e.product_id),
        ...file.products.map((e: LibraryFileProduct) => e.product_id)
      ];
      return [...new Set(productIds)]; // Unique elements only
    },
    renameAttachment(
      payload: FileToRename,
      file: LibraryFile
    ): Promise<AxiosResponse<FileToUpsertResponse>> {
      const productIds = this.getProductIdsToUpdate(file);
      this.productsStore.areProductDetailsUpdating = true;
      this.contentStructureStore.isContentStructureUpdating = true;
      this.filesStore.areFilesUpdating = true;
      return this.filesStore
        .renameFile(payload)
        .then((response: AxiosResponse<FileToUpsertResponse>) => {
          productIds.forEach((id: number) =>
            this.productsStore.populateProductDetails(id)
          );
          return response;
        });
    },
    addOnlineAttachment(
      payload: OnlineFileToAttachToSection | OnlineFileToAttachToProduct
    ): Promise<AxiosResponse<FileToUpsertResponse>> {
      this.productsStore.areProductDetailsUpdating = true;
      this.contentStructureStore.isContentStructureUpdating = true;
      this.filesStore.areFilesUpdating = true;
      return this.filesStore
        .attachOnlineFile(payload)
        .then((response: AxiosResponse<FileToUpsertResponse>) => {
          this.productsStore.populateProductDetails(payload.productId);
          return response;
        });
    },
    linkFileToProduct(payload: FileToLinkToProduct): Promise<[void, void]> {
      return this.filesStore.linkExistingFileToProduct(payload);
    },
    linkFileToSection(payload: FileToLinkToSection): Promise<[void, void]> {
      return this.filesStore.linkExistingFileToSection(payload);
    },
    unlinkFileFromProduct(
      payload: FileToUnlinkFromProduct
    ): Promise<[void, void]> {
      return this.filesStore.deleteFileLinkFromProduct(payload);
    },
    unlinkFileFromSection(
      payload: FileToUnlinkFromSection
    ): Promise<[void, void]> {
      return this.filesStore.deleteFileLinkFromSection(payload);
    },
    uploadFileVersion(payload: FileToUploadForMixin): Promise<void> {
      const productId = payload.productId;
      this.productsStore.areProductDetailsUpdating = true;
      this.contentStructureStore.isContentStructureUpdating = true;
      return this.filesStore.uploadNewFileVersion(payload.formData).then(() => {
        if (productId) {
          this.productsStore.populateProductDetails(productId);
        }
      });
    },
    uploadFile(payload: FileToUploadForMixin): Promise<void> {
      const productId = payload.productId;
      this.productsStore.areProductDetailsUpdating = true;
      this.contentStructureStore.isContentStructureUpdating = true;
      this.filesStore.areFilesUpdating = true;
      return this.filesStore.uploadNewFile(payload.formData).then(() => {
        if (productId) {
          this.productsStore.populateProductDetails(productId);
        }
      });
    },
    removeFile(file: LibraryFile) {
      const productIds = this.getProductIdsToUpdate(file);
      this.productsStore.areProductDetailsUpdating = true;
      this.contentStructureStore.isContentStructureUpdating = true;
      this.filesStore.areFilesUpdating = true;
      return this.filesStore.deleteFile(file.id).then(() => {
        productIds.forEach((id: number) => {
          this.productsStore.populateProductDetails(id);
        });
      });
    },
    setAsMediaContent(
      fileLocation: string,
      fileId: number,
      section?: ContentStructureSection
    ): void {
      const sectionToSet = section
        ? section
        : this.uiFilesStore.sectionToAttachFile;
      if (
        !sectionToSet.authored_content ||
        fileId !== sectionToSet.authored_content.externalContent.attachmentId ||
        fileLocation !== sectionToSet.authored_content.externalContent
      ) {
        const sectionAuthContent = sectionToSet.authored_content
          ? sectionToSet.authored_content
          : {};
        const newAuthContent = Object.assign({}, sectionAuthContent, {
          externalContent: {
            attachmentId: fileId
          }
        });
        this.contentStore.upsertSections({
          productIds: [sectionToSet.product_id],
          sections: [
            {
              id: sectionToSet.id,
              authored_content: newAuthContent
            }
          ]
        });
      }
    },
    unsetAsMediaContent(section?: ContentStructureSection): void {
      const sectionToUnset = section
        ? section
        : this.uiFilesStore.sectionToAttachFile;
      const newAuthContent = sectionToUnset.authored_content
        ? Object.assign({}, sectionToUnset.authored_content)
        : {};
      delete newAuthContent.externalContent;
      this.contentStore.upsertSections({
        productIds: [sectionToUnset.product_id],
        sections: [
          {
            id: sectionToUnset.id,
            authored_content: newAuthContent
          }
        ]
      });
    }
  }
};

export default FilesMixin;
