import { useUIFilesStore } from "@/stores/ui-files.store";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import { useContentStore } from "@/stores/content.store";
import { useFilesStore } from "@/stores/files.store";
import {
  LibraryFile,
  LibraryFileProduct,
  LibraryFileSection
} from "@/models/files.model";
import {
  FileToLinkToProduct,
  FileToLinkToSection,
  FileToRename,
  FileToUnlinkFromProduct,
  FileToUnlinkFromSection,
  FileToUploadForMixin,
  FileToUpsertResponse,
  OnlineFileToAttachToProduct,
  OnlineFileToAttachToSection
} from "@/models/api/files.model";
import { AxiosResponse } from "axios";
import { ContentStructureSection } from "@/models/content-structure.model";

export const useFileManager = () => {
  const uiFilesStore = useUIFilesStore();
  const productsStore = useProductsStore();
  const contentStructureStore = useContentStructureStore();
  const contentStore = useContentStore();
  const filesStore = useFilesStore();

  const getProductIdsToUpdate = (file: LibraryFile): number[] => {
    const productIds = [
      ...file.sections.map((e: LibraryFileSection) => e.product_id),
      ...file.products.map((e: LibraryFileProduct) => e.product_id)
    ];
    return [...new Set(productIds)];
  };

  const renameAttachment = (
    payload: FileToRename,
    file: LibraryFile
  ): Promise<AxiosResponse<FileToUpsertResponse>> => {
    const productIds = getProductIdsToUpdate(file);
    productsStore.areProductDetailsUpdating = true;
    contentStructureStore.isContentStructureUpdating = true;
    filesStore.areFilesUpdating = true;
    return filesStore
      .renameFile(payload)
      .then((response: AxiosResponse<FileToUpsertResponse>) => {
        productIds.forEach((id: number) =>
          productsStore.populateProductDetails(id)
        );
        return response;
      });
  };

  const addOnlineAttachment = (
    payload: OnlineFileToAttachToSection | OnlineFileToAttachToProduct
  ): Promise<AxiosResponse<FileToUpsertResponse>> => {
    productsStore.areProductDetailsUpdating = true;
    contentStructureStore.isContentStructureUpdating = true;
    filesStore.areFilesUpdating = true;
    return filesStore
      .attachOnlineFile(payload)
      .then((response: AxiosResponse<FileToUpsertResponse>) => {
        productsStore.populateProductDetails(payload.productId);
        return response;
      });
  };
  const uploadFileVersion = (payload: FileToUploadForMixin): Promise<void> => {
    const productId = payload.productId;
    if (productId) {
      productsStore.areProductDetailsUpdating = true;
      contentStructureStore.isContentStructureUpdating = true;
    }
    return filesStore.uploadNewFileVersion(payload.formData).then(() => {
      if (productId) {
        productsStore.populateProductDetails(productId);
      }
    });
  };
  const uploadFile = (payload: FileToUploadForMixin): Promise<void> => {
    const productId = payload.productId;
    if (productId) {
      productsStore.areProductDetailsUpdating = true;
      contentStructureStore.isContentStructureUpdating = true;
    }
    return filesStore.uploadNewFile(payload.formData).then(() => {
      if (productId) {
        productsStore.populateProductDetails(productId);
      }
    });
  };

  const linkFileToProduct = (
    payload: FileToLinkToProduct
  ): Promise<[void, void]> => {
    return filesStore.linkExistingFileToProduct(payload);
  };
  const unlinkFileFromProduct = (
    payload: FileToUnlinkFromProduct
  ): Promise<[void, void]> => {
    return filesStore.deleteFileLinkFromProduct(payload);
  };

  const linkFileToSection = (
    payload: FileToLinkToSection
  ): Promise<[void, void]> => {
    return filesStore.linkExistingFileToSection(payload);
  };
  const unlinkFileFromSection = (
    payload: FileToUnlinkFromSection
  ): Promise<[void, void]> => {
    return filesStore.deleteFileLinkFromSection(payload);
  };

  const removeFile = (file: LibraryFile) => {
    const productIds = getProductIdsToUpdate(file);
    productsStore.areProductDetailsUpdating = true;
    contentStructureStore.isContentStructureUpdating = true;
    return filesStore.deleteFile(file.id).then(() => {
      productIds.forEach((id: number) => {
        productsStore.populateProductDetails(id);
      });
    });
  };

  const setAsMediaContent = (
    fileLocation: string,
    fileId: number,
    section?: ContentStructureSection
  ): void => {
    const sectionToSet = section ? section : uiFilesStore.sectionToAttachFile;
    if (
      sectionToSet &&
      (!sectionToSet.authored_content ||
        fileId !== sectionToSet.authored_content.externalContent.attachmentId ||
        fileLocation !== sectionToSet.authored_content.externalContent)
    ) {
      const sectionAuthContent = sectionToSet.authored_content
        ? sectionToSet.authored_content
        : {};
      const newAuthContent = Object.assign({}, sectionAuthContent, {
        externalContent: {
          attachmentId: fileId
        }
      });
      contentStore.upsertSections({
        productIds: [sectionToSet.product_id],
        sections: [
          {
            id: sectionToSet.id,
            authored_content: newAuthContent
          }
        ]
      });
    }
  };
  const unsetAsMediaContent = (section?: ContentStructureSection): void => {
    const sectionToUnset = section ? section : uiFilesStore.sectionToAttachFile;
    if (sectionToUnset) {
      const newAuthContent = sectionToUnset.authored_content
        ? Object.assign({}, sectionToUnset.authored_content)
        : {};
      delete newAuthContent.externalContent;
      contentStore.upsertSections({
        productIds: [sectionToUnset.product_id],
        sections: [
          {
            id: sectionToUnset.id,
            authored_content: newAuthContent
          }
        ]
      });
    }
  };

  return {
    getProductIdsToUpdate,
    renameAttachment,
    addOnlineAttachment,
    uploadFileVersion,
    uploadFile,
    linkFileToProduct,
    unlinkFileFromProduct,
    linkFileToSection,
    unlinkFileFromSection,
    removeFile,
    setAsMediaContent,
    unsetAsMediaContent
  };
};
