import { defineStore } from "pinia";
import { ref } from "vue";
import { GeneratedContentStructure } from "@/models/content-structure.model";
import { ProductItem } from "@/models/product.model";
import csHelper from "@/utils/product/content-structure-helpers";

export const useContentStructureStore = defineStore("contentStructure", () => {
  const isContentStructureLoading = ref<boolean>(true);
  const isContentStructureUpdating = ref<boolean>(false);
  const contentStructure = ref<GeneratedContentStructure[]>([]);
  const setContentStructure = (params: ProductItem) => {
    contentStructure.value = (
      contentStructure.value as GeneratedContentStructure[]
    ).filter((e: GeneratedContentStructure) => {
      return e.product_id !== params.id;
    });
    contentStructure.value.push(
      csHelper.generateContentStructure(params.sections, params.id)
    );
    isContentStructureUpdating.value = false;
    isContentStructureLoading.value = false;
  };
  const clearContentStructure = (productId: number) => {
    contentStructure.value = (
      contentStructure.value as GeneratedContentStructure[]
    ).filter((e: GeneratedContentStructure) => {
      return e.product_id !== productId;
    });
    isContentStructureUpdating.value = false;
    isContentStructureLoading.value = false;
  };

  return {
    isContentStructureLoading,
    isContentStructureUpdating,
    contentStructure,
    setContentStructure,
    clearContentStructure
  };
});
