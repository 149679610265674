import { ProductUser } from "./user.model";
import { ContentTemplate } from "@/models/content-templates.model";
import { ProductSection } from "@/models/product-section.model";
import { AttachmentTypes } from "@/models/files.model";

export interface ProductSummaryItem {
  available_permissions: ProductPermission[];
  allocated_permissions: ProductPermission[];
  contains_tasks: boolean;
  curriculum_id: number | null;
  delivery: ProductDelivery;
  description: string;
  id: number;
  image_location: string;
  image_size: number;
  is_xapi: boolean;
  isbn: string;
  launch_date: number;
  name: string;
  namespace: string;
  product_type: string;
  production_manager: number;
  programme_id: number;
  programme_name: string;
  status: ProductStatus;
}

export interface ProductFeature {
  activated_feature_id: number;
  id: number;
  isEnabled: boolean;
  title: string;
  features: string[];
}
export interface ProductItem {
  adaptive_learning_systems: ProductAdaptiveLearningSystem[];
  available_permissions: ProductPermission[];
  allocated_permissions: ProductPermission[];
  batches: ProductBatch[];
  component_definitions: ContentTemplate[];
  contains_tasks: boolean;
  curriculum_id: null | number;
  delivery: ProductDelivery;
  description: string;
  features: ProductFeature[];
  files: ProductFiles;
  id: number;
  image_location: string;
  image_size: number;
  is_xapi: boolean;
  isbn: string;
  launch_date: number;
  name: string;
  namespace: string;
  product_type: string;
  production_manager: number;
  programme_id: number;
  programme_name: string;
  sections: ProductSection[];
  status: ProductStatus;
  users: ProductUser[];
  workflows: ProductWorkflow[];
}
export interface UpdatedProductDetails {
  curriculum_id?: number;
  is_xapi?: boolean;
  name?: string;
  product_type?: string;
  manager?: number;
  launch_date?: number;
  delivery?: ProductDelivery;
  status?: ProductStatus;
  description?: string;
  isbn?: string;
  namespace?: string;
  remove_image?: string;
}

export interface ProductPermission {
  id: number;
  name: string;
}

export interface ProductBatch {
  deadlines: ProductBatchDeadline[];
  id: number;
  is_enabled: boolean;
  name: string;
}
export interface ProductBatchDeadline {
  batch_id: number;
  date: number;
  id: number;
  is_enabled: number;
  section_type_id: number;
  workflow_id: number;
  workflow_state_id: number;
}
export enum ProductDelivery {
  ONLINE = "online",
  PRINT_EBOOK = "Print & Ebook",
  PRINT = "Print"
}

export interface ProductFiles {
  editorial: ProductFile[];
  managerial: ProductFile[];
}

export interface ProductFile {
  attachment_type: AttachmentTypes;
  file_created_by: number;
  file_created_on: number;
  file_deleted_by: boolean;
  file_deleted_on: boolean;
  file_id: number;
  file_to_product_id: number;
  is_external: number;
  link_created_by: number;
  link_created_on: number;
  link_deleted_by: number;
  link_deleted_on: number;
  location: string;
  name: string;
  product_id: number;
  version_id: number;
}
export enum ProductStatus {
  ARCHIVE = "Archive",
  ACTIVE = "Active",
  DRAFT = "Draft"
}
interface ProductWorkflow {
  created_on: number;
  id: number;
  is_enabled: boolean;
  name: string;
  section_type_id: number;
}

export interface ProductAdaptiveLearningSystem {
  id: number;
  name: string;
}
