import DAO from "@/api/DAO";
import { PERMISSIONS_URL } from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import { PermissionPayload } from "@/models/available-permissions.model";

const addUserPermissions = (
  payload: PermissionPayload
): Promise<AxiosResponse> => {
  return DAO().put(PERMISSIONS_URL, payload);
};

const removeUserPermissions = (
  payload: PermissionPayload
): Promise<AxiosResponse> => {
  const product = payload.product_id ? `/${payload.product_id}` : "";
  return DAO().delete(
    PERMISSIONS_URL + `/${payload.user_id}/${payload.permission_id}${product}`
  );
};

export { addUserPermissions, removeUserPermissions };
