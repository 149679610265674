<template>
  <div class="margin-b-standard" data-testing="mcq-card">
    <RightSidebarTitle :title="'MCQ Mode'" />
    <div class="tools-buttons">
      <!--Preview button-->
      <button
        :disabled="isPreviewing || !isContentAuthoringTier2FeatureEnabled"
        @click="goToPreview"
        class="btn-3"
        type="button"
        data-testing="mcq-card-btn-preview"
      >
        <SVGIcon :path="mdiPresentationPlay" />
        Preview
      </button>
      <!--Download button-->
      <button
        :disabled="isDownloading || !isContentAuthoringTier2FeatureEnabled"
        @click="triggerDownload"
        class="btn-3"
        type="button"
        data-testing="mcq-card-btn-download"
      >
        <SVGIcon :path="mdiDownload" />
        Download
      </button>
      <!--Open editor button-->
      <span
        v-if="!isContentAuthoringTier2FeatureEnabled"
        class="btn-3 disabled margin-r-standard"
        data-testing="qti-card-btn-preview"
      >
        <SVGIcon :path="mdiPencil" />
        Open editor
      </span>
      <router-link
        v-if="isContentAuthoringTier2FeatureEnabled"
        class="btn-3"
        data-testing="mcq-card-btn-view-link"
        :to="{
          name: 'mcq',
          params: {
            productId: uiContentStore.sectionToDisplay.productId,
            sectionId: uiContentStore.sectionToDisplay.sectionId
          }
        }"
      >
        <SVGIcon :path="mdiPencil" />
        Open editor
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import API from "@/api/index";
import FiveATHelper from "@/utils/5AT-helper";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import { AxiosResponse } from "axios";
import { MCQResponse } from "@/models/api/mcq.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mdiPencil, mdiDownload, mdiPresentationPlay } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIContentStore } from "@/stores/ui-content.store";

interface Data {
  isPreviewing: boolean;
  isDownloading: boolean;
  mdiPencil: string;
  mdiPresentationPlay: string;
  mdiDownload: string;
}

export default defineComponent({
  name: "MCQ",
  data(): Data {
    return {
      isPreviewing: false,
      isDownloading: false,
      mdiPencil,
      mdiDownload,
      mdiPresentationPlay
    };
  },
  props: {
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    },
    isContentAuthoringTier2FeatureEnabled: {
      required: true,
      type: Boolean
    }
  },
  components: {
    RightSidebarTitle
  },
  computed: {
    ...mapStores(useUIContentStore)
  },
  methods: {
    goToPreview(): void {
      if (this.uiContentStore.sectionToDisplay) {
        this.isPreviewing = true;
        API.mcq
          .preview({
            var_definition: FiveATHelper.generateDefinition(this.section),
            sections_id: this.uiContentStore.sectionToDisplay.sectionId
          })
          .then((response: AxiosResponse<MCQResponse>): void => {
            this.isPreviewing = false;
            if (response.status === 200) {
              window.open(response.data.data.url, "_blank");
            } else {
              throw new Error("Unable to preview content");
            }
          });
      }
    },
    triggerDownload(): void {
      if (this.uiContentStore.sectionToDisplay) {
        this.isDownloading = true;
        API.mcq
          .download({
            var_definition: FiveATHelper.generateDefinition(this.section),
            sections_id: this.uiContentStore.sectionToDisplay.sectionId
          })
          .then((response: AxiosResponse<MCQResponse>): void => {
            this.isDownloading = false;
            if (response.status === 200) {
              window.open(response.data.data.url);
            } else {
              throw new Error("Unable to download content");
            }
          });
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.tools-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0;
}
</style>
