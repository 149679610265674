import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-90e40032"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "margin-r-half" }
const _hoisted_3 = ["value", "onChange"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "margin-r-half" }
const _hoisted_6 = { class: "list-unstyled" }
const _hoisted_7 = ["for"]
const _hoisted_8 = ["id", "name", "value", "checked", "onChange"]
const _hoisted_9 = ["id", "name", "checked", "onChange"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "margin-r-half" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "margin-r-half" }
const _hoisted_15 = { class: "list-disk" }
const _hoisted_16 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hint = _resolveComponent("Hint")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createBlock(_component_Collapse, {
    class: _normalizeClass({ updating: _ctx.isUpdating })
  }, _createSlots({
    title: _withCtx(() => [
      _createTextVNode(" Metadata "),
      (_ctx.XAPIErrors.length > 0)
        ? (_openBlock(), _createBlock(_component_Hint, {
            key: 0,
            type: 'error',
            position: 'top'
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.XAPIErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.canEditContent)
      ? {
          name: "content",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.section.metadata_instances, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                (item.metadata_definition.type === 'Text Free')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("strong", _hoisted_2, _toDisplayString(item.metadata_definition.name) + ":", 1),
                      _createElementVNode("input", {
                        type: "text",
                        value: item.authored_text,
                        onChange: ($event: any) => (_ctx.updateMetadataText(item.id, $event))
                      }, null, 40, _hoisted_3)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("strong", _hoisted_5, [
                        _createTextVNode(_toDisplayString(item.metadata_definition.name) + " ", 1),
                        _createElementVNode("small", null, "(select " + _toDisplayString(item.metadata_definition.type === "Single Option"
                  ? "one"
                  : "multiple") + ") ", 1)
                      ]),
                      _createElementVNode("ul", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMetadataAllowedValues(
                item.metadata_definition.id
              ), (value) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: value.id
                          }, [
                            _createElementVNode("label", {
                              for: value.id
                            }, [
                              (item.metadata_definition.type === 'Single Option')
                                ? (_openBlock(), _createElementBlock("input", {
                                    key: 0,
                                    id: value.id,
                                    type: "radio",
                                    name: item.metadata_definition.name,
                                    value: value.display_name,
                                    checked: _ctx.isMetadataValueChecked(item.id, value.id),
                                    onChange: ($event: any) => (_ctx.toggleMetadataAllocatedValue(item.id, $event))
                                  }, null, 40, _hoisted_8))
                                : _createCommentVNode("", true),
                              (item.metadata_definition.type === 'Multiple Options')
                                ? (_openBlock(), _createElementBlock("input", {
                                    key: 1,
                                    id: value.id,
                                    type: "checkbox",
                                    name: item.metadata_definition.name,
                                    checked: _ctx.isMetadataValueChecked(item.id, value.id),
                                    onChange: ($event: any) => (_ctx.toggleMetadataAllocatedValue(item.id, $event))
                                  }, null, 40, _hoisted_9))
                                : _createCommentVNode("", true),
                              _createTextVNode(" " + _toDisplayString(value.display_name), 1)
                            ], 8, _hoisted_7)
                          ]))
                        }), 128))
                      ])
                    ])),
                (index < _ctx.section.metadata_instances.length - 1)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          key: "0"
        }
      : {
          name: "content",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedMetadata, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                (item.metadata_definition.type === 'Text Free')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("strong", _hoisted_12, _toDisplayString(item.metadata_definition.name) + ":", 1),
                      _createElementVNode("span", null, _toDisplayString(item.authored_text), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("strong", _hoisted_14, _toDisplayString(item.metadata_definition.name), 1),
                      _createElementVNode("ul", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMetadataCheckedValues(item.id), (value) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: value.id
                          }, [
                            _createElementVNode("span", null, _toDisplayString(value.name), 1)
                          ]))
                        }), 128))
                      ])
                    ])),
                (index < _ctx.displayedMetadata.length - 1)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_16))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          key: "1"
        }
  ]), 1032, ["class"]))
}