import { useProductsManager } from "@/composables/products-manager";
import { ProductFeature } from "@/models/product.model";
import cnst from "@/utils/constants";
import { computed } from "vue";

export const useProductFeaturesManager = () => {
  const { product } = useProductsManager();

  const getAppropriateFeature = (
    featureId: number
  ): ProductFeature | undefined => {
    if (product.value) {
      return product.value.features.find((feature: ProductFeature): boolean => {
        return feature.id === featureId;
      });
    }
    return undefined;
  };

  const isAssetManagementFeatureEnabled = computed<boolean>(() => {
    const appropriateFeature = getAppropriateFeature(
      cnst.userInterfaceFeatures.assetManagementTier2.id
    );
    return !!appropriateFeature && appropriateFeature.isEnabled;
  });
  const isWorkflowManagementFeatureEnabled = computed<boolean>(() => {
    const appropriateFeature = getAppropriateFeature(
      cnst.userInterfaceFeatures.workflowManagement.id
    );
    return !!appropriateFeature && appropriateFeature.isEnabled;
  });
  const isContentAuthoringTier1FeatureEnabled = computed<boolean>(() => {
    const appropriateFeature = getAppropriateFeature(
      cnst.userInterfaceFeatures.contentAuthoringTier1.id
    );
    return !!appropriateFeature && appropriateFeature.isEnabled;
  });
  const isContentAuthoringTier2FeatureEnabled = computed<boolean>(() => {
    const appropriateFeature = getAppropriateFeature(
      cnst.userInterfaceFeatures.contentAuthoringTier2.id
    );
    return !!appropriateFeature && appropriateFeature.isEnabled;
  });

  return {
    getAppropriateFeature,
    isAssetManagementFeatureEnabled,
    isWorkflowManagementFeatureEnabled,
    isContentAuthoringTier1FeatureEnabled,
    isContentAuthoringTier2FeatureEnabled
  };
};
