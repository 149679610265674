import Swal, { SweetAlertOptions, SweetAlertPosition } from "sweetalert2";
import { getIcon } from "@/utils/notification-helpers";

interface Toast {
  message: string;
  duration?: number;
  type?: string;
}
interface Dialog {
  message: string;
  cancelText: string;
  confirmText: string;
  type?: string;
  onConfirm: Function;
}
interface Snackbar {
  message?: string;
  html?: string;
  confirmText?: string;
  type?: string;
  onConfirm?: Function;
  position?: SweetAlertPosition;
}

export const useNotificationsManager = () => {
  const showToast = (settings: Toast) => {
    Swal.fire({
      toast: true,
      text: settings.message,
      position: "top",
      timer: settings.duration ?? 3000,
      showConfirmButton: false,
      background: "#f3f3f3",
      iconHtml: getIcon(settings.type ?? "is-info")
    });
  };
  const showDialog = (settings: Dialog) => {
    Swal.fire({
      text: settings.message,
      showCancelButton: true,
      confirmButtonText: settings.confirmText,
      cancelButtonText: settings.cancelText,
      iconHtml: getIcon(settings.type ?? "is-question"),
      buttonsStyling: false,
      customClass: {
        cancelButton: "button",
        confirmButton: "button is-primary"
      },
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        settings.onConfirm();
      }
    });
  };
  const showSnackbar = (settings: Snackbar) => {
    const options: SweetAlertOptions = {
      showCancelButton: false,
      showConfirmButton: false,
      iconHtml: getIcon(settings.type ?? "is-info"),
      buttonsStyling: false,
      customClass: {
        confirmButton: "button is-primary"
      },
    };
    if (settings.message) {
      options.text = settings.message;
    } else if (settings.html) {
      options.html = settings.html;
    }
    if (settings.confirmText) {
      options.confirmButtonText = settings.confirmText;
      options.showConfirmButton = true;
    }
    if (settings.position) {
      options.position = settings.position;
    }
    Swal.fire(options).then(result => {
      if (result.isConfirmed && settings.onConfirm) {
        settings.onConfirm();
      }
    });
  };

  return {
    showToast,
    showDialog,
    showSnackbar
  };
};
