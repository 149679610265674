// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { mapStores } from "pinia";
import { useOrganizationStore } from "@/stores/organization.store";

const IS_PRODUCTION = process.env.NODE_ENV === "production";
const ORGANIZATION_IDS = {
  PRODUCTION: {
    ADAPTEMY_TEST: 1,
    ADAPTEMY_DEVELOPMENT: 2,
    ADAPTEMY: 3,
    FOLENS: 4,
    DIE_KEURE: 5,
    ICAR: 17
  }
};

const FeatureFlagsMixin = {
  computed: {
    ...mapStores(useOrganizationStore),
    ffShouldTaskNotificationsBeSent(): boolean {
      return IS_PRODUCTION;
    },
    ffIsFolensOrganization(): boolean {
      return (
        IS_PRODUCTION &&
        ORGANIZATION_IDS.PRODUCTION.FOLENS ===
          this.organizationStore.organization.id
      );
    },
    ffIsIcarOrganization(): boolean {
      return (
        ORGANIZATION_IDS.PRODUCTION.ICAR ===
        this.organizationStore.organization.id
      );
    },
    ffIsProduction(): boolean {
      return IS_PRODUCTION;
    }
  },
  methods: {
    hasDeployButton(isQTI: boolean, productId: number): boolean {
      return isQTI && productId === 17 && IS_PRODUCTION;
    }
  }
};
export default FeatureFlagsMixin;
