import DAO from "@/api/DAO";
import {
  PRODUCTS_URL,
  PRODUCT_SUMMARIES_URL,
  PRODUCT_DETAILS_URL,
  PRODUCT_IMG_URL,
  PRODUCT_FEATURE_URL
} from "@/api/URL-constants";
import cnst from "@/utils/constants";
import { AxiosResponse } from "axios";
import {
  CreateProductResponse,
  ProductFeatureToUpdate,
  GetProductResponse,
  GetProductSummaryResponse,
  ProductToCreate,
  ProductToUpdate
} from "@/models/api/product.model";
import { ProductItem, ProductSummaryItem } from "@/models/product.model";

const getAll = (): Promise<ProductSummaryItem[]> => {
  return DAO()
    .get(PRODUCT_SUMMARIES_URL)
    .then((response: AxiosResponse<GetProductSummaryResponse>) => {
      if (response.status === 200) {
        return response.data.data;
      } else if (response.status === 204) {
        return [];
      } else {
        throw new Error(cnst.productError.loading + "s");
      }
    });
};

const get = (productId: number): Promise<ProductItem> => {
  return DAO()
    .get(`${PRODUCT_DETAILS_URL}/${productId}`)
    .then((response: AxiosResponse<GetProductResponse>) => {
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          throw new Error(cnst.productError.notFound);
        }
        return response.data.data;
      } else {
        throw new Error(cnst.productError.loading);
      }
    });
};

const create = (
  payload: ProductToCreate
): Promise<AxiosResponse<CreateProductResponse>> => {
  return DAO().post(PRODUCTS_URL, payload);
};

const update = (payload: ProductToUpdate): Promise<AxiosResponse> => {
  return DAO().put(PRODUCTS_URL, payload);
};

const remove = (productId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${PRODUCTS_URL}/${productId}`);
};

const addImage = (payload: FormData): Promise<AxiosResponse> => {
  return DAO().post(PRODUCT_IMG_URL, payload, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const updateFeature = (
  payload: ProductFeatureToUpdate
): Promise<AxiosResponse> => {
  return DAO().put(PRODUCT_FEATURE_URL, payload);
};

export { getAll, get, create, update, remove, addImage, updateFeature };
