<template>
  <Collapse>
    <template v-slot:title>
      <span>{{ title }}</span>
    </template>
    <template v-slot:content>
      <p class="pb-0" v-if="!canEditContent">{{ section.instruction }}</p>
      <textarea
        v-else
        id="description-text"
        ref="description-text"
        v-model="section.instruction"
        @blur="saveDescriptionText($event)"
        data-testing="description"
      ></textarea>
    </template>
  </Collapse>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import cnst from "@/utils/constants";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mapStores } from "pinia";
import { useContentStore } from "@/stores/content.store";

export default defineComponent({
  name: "ContentDescription",
  props: {
    sectionId: {
      required: true,
      type: Number
    },
    productId: {
      required: true,
      type: Number
    },
    section: {
      required: true,
      type: Object as PropType<ContentStructureSection>
    },
    canEditContent: {
      required: true,
      type: Boolean
    }
  },
  components: { Collapse },
  computed: {
    ...mapStores(useContentStore),
    title(): string {
      return `Description for ${this.section.name}`;
    }
  },
  methods: {
    saveDescriptionText(event: { currentTarget: HTMLTextAreaElement }): void {
      let description = event.currentTarget.value;
      this.contentStore
        .upsertSections({
          productIds: [this.productId],
          sections: [
            {
              id: this.sectionId,
              instruction: description
            }
          ]
        })
        .catch((error: Error) => {
          this.$buefy.toast.open({
            message: `${cnst.section.descriptionError}: ${error.message}`,
            type: "is-danger",
            duration: 3000
          });
        });
    }
  }
});
</script>
<style lang="scss" scoped>
.description-container:focus-within .plus-icon {
  display: none;
}
.description-text.editing {
  border: 1px solid black;
  padding: 0.5rem;
}
button {
  .plus-icon {
    color: $primary-color;
  }
  &:focus .plus-icon,
  &:hover .plus-icon {
    color: $primary-color-light;
  }
  &:hover[title]::after {
    white-space: pre;
  }
}
</style>
