<template>
  <Collapse>
    <template v-slot:title>
      <span>Identifiers</span>
    </template>
    <template v-slot:content>
      <div v-if="canEditContent">
        <div class="margin-b-standard">
          <RightSidebarTitle :title="'Thoth id'" />
          <input type="text" readonly disabled :value="internalId" />
        </div>
        <div class="margin-b-standard">
          <RightSidebarTitle :title="'External id'" />
          <input type="text" v-model.trim="newExternalId" />
        </div>
        <button
          class="btn-3"
          @click="updateExternalId"
          :disabled="isSaveButtonDisabled"
        >
          <SVGIcon :path="mdiContentSave" />
          Save
        </button>

        <p v-if="externalIdError" class="warning mt-4 pad-0-bottom">
          <SVGIcon :path="mdiAlert" />
          <small class="margin-l-quarter">{{ externalIdError }}</small>
        </p>
      </div>
      <div v-else>
        <p class="pb-2"><strong>Thoth id: </strong>{{ internalId }}</p>
        <p class="pb-0"><strong>External id: </strong> {{ externalId }}</p>
      </div>
    </template>
  </Collapse>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import cnst from "@/utils/constants";
import { ProductSection } from "@/models/product-section.model";
import { mdiAlert, mdiContentSave } from "@mdi/js";
import { mapStores } from "pinia";
import { useContentStore } from "@/stores/content.store";

interface Data {
  newExternalId: string;
  externalIdError: string;
  mdiAlert: string;
  mdiContentSave: string;
}

export default defineComponent({
  name: "ContentIdentifiers",
  data(): Data {
    return {
      newExternalId: "",
      externalIdError: "",
      mdiAlert,
      mdiContentSave
    };
  },
  props: {
    productId: {
      required: true
    },
    internalId: {
      required: true,
      type: Number
    },
    externalId: {
      required: true,
      type: String
    },
    sections: {
      required: true,
      type: Array as PropType<ProductSection[]>
    },
    canEditContent: {
      required: true,
      type: Boolean
    }
  },
  components: {
    Collapse,
    RightSidebarTitle
  },
  computed: {
    ...mapStores(useContentStore),
    isSaveButtonDisabled(): boolean {
      return this.newExternalId === this.externalId || !!this.externalIdError;
    }
  },
  methods: {
    isExternalIdValid(): boolean {
      this.externalIdError = "";
      return !this.sections.some((section: ProductSection) => {
        return (
          section.client_external_id === this.newExternalId &&
          section.id !== this.internalId
        );
      });
    },
    updateExternalId(): void {
      let isValid;
      if (this.newExternalId) {
        // if a user entered the external id, this id must be unique
        isValid = this.isExternalIdValid();
      } else {
        // user can send empty string if he wants to remove the old external id and doesn't set a new one
        isValid = this.newExternalId !== this.externalId;
      }
      if (isValid) {
        this.contentStore
          .upsertSections({
            productIds: [Number(this.productId)],
            sections: [
              {
                id: this.internalId,
                client_external_id: this.newExternalId
              }
            ]
          })
          .catch((error: Error) => {
            this.externalIdError = error.message;
          });
      } else {
        this.externalIdError = cnst.section.nonUniqueExternalId;
      }
    }
  },
  mounted() {
    this.newExternalId = this.externalId;
  },
  unmounted() {
    this.newExternalId = "";
  },
  watch: {
    internalId() {
      this.newExternalId = this.externalId;
    },
    newExternalId() {
      this.externalIdError = "";
    }
  }
});
</script>
<style scoped></style>
