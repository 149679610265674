import { defineStore } from "pinia";
import API from "@/api";
import { ref } from "vue";
import { LibraryFile } from "@/models/files.model";
import { AxiosResponse } from "axios";
import {
  FileToLinkToProduct,
  FileToLinkToSection,
  FileToRename,
  FileToUnlinkFromProduct,
  FileToUnlinkFromSection,
  FileToUpsertResponse,
  GetAllFilesResponse,
  OnlineFileToAttachToProduct,
  OnlineFileToAttachToSection
} from "@/models/api/files.model";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";

export const useFilesStore = defineStore("files", () => {
  const productsStore = useProductsStore();
  const contStrStore = useContentStructureStore();

  const areFilesLoading = ref<boolean>(true);
  const areFilesUpdating = ref<boolean>(false);
  const files = ref<LibraryFile[] | null>(null);

  const populateFiles = () => {
    areFilesUpdating.value = true;
    return API.file
      .getAll()
      .then((response: AxiosResponse<GetAllFilesResponse>) => {
        areFilesLoading.value = false;
        areFilesUpdating.value = false;
        files.value = response.data.data.filter(
          (f: LibraryFile) => !f.deleted_on
        );
      });
  };

  const attachOnlineFile = (
    payload: OnlineFileToAttachToSection | OnlineFileToAttachToProduct
  ): Promise<AxiosResponse<FileToUpsertResponse>> => {
    return API.file
      .upsert(payload)
      .then(async (response: AxiosResponse<FileToUpsertResponse>) => {
        await populateFiles();
        return response;
      });
  };
  const renameFile = (
    payload: FileToRename
  ): Promise<AxiosResponse<FileToUpsertResponse>> => {
    areFilesUpdating.value = true;
    return API.file
      .upsert(payload)
      .then(async (response: AxiosResponse<FileToUpsertResponse>) => {
        await populateFiles();
        return response;
      });
  };
  const uploadNewFile = (payload: FormData): Promise<void> => {
    areFilesUpdating.value = true;
    return API.file.upload(payload).then(() => {
      return populateFiles();
    });
  };
  const uploadNewFileVersion = (payload: FormData): Promise<void> => {
    areFilesUpdating.value = true;
    return API.file.upload(payload).then(() => {
      return populateFiles();
    });
  };
  const deleteFile = (fId: number): Promise<void> => {
    areFilesUpdating.value = true;
    return API.file.remove(fId).then(() => {
      return populateFiles();
    });
  };

  const linkExistingFileToSection = (payload: FileToLinkToSection) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    areFilesUpdating.value = true;
    return API.file.linkToSection(payload).then(() => {
      return Promise.all([
        productsStore.populateProductDetails(payload.productId),
        populateFiles()
      ]);
    });
  };
  const deleteFileLinkFromSection = (payload: FileToUnlinkFromSection) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    areFilesUpdating.value = true;
    return API.file.unlinkFromSection(payload.linkId).then(() => {
      return Promise.all([
        productsStore.populateProductDetails(payload.productId),
        populateFiles()
      ]);
    });
  };
  const linkExistingFileToProduct = (payload: FileToLinkToProduct) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    areFilesUpdating.value = true;
    return API.file.linkToProduct(payload as FileToLinkToProduct).then(() => {
      return Promise.all([
        productsStore.populateProductDetails(payload.product_id),
        populateFiles()
      ]);
    });
  };
  const deleteFileLinkFromProduct = (payload: FileToUnlinkFromProduct) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    areFilesUpdating.value = true;
    return API.file.unlinkFromProduct(payload.linkId).then(() => {
      return Promise.all([
        productsStore.populateProductDetails(payload.productId),
        populateFiles()
      ]);
    });
  };

  return {
    areFilesLoading,
    areFilesUpdating,
    files,
    populateFiles,
    attachOnlineFile,
    renameFile,
    uploadNewFile,
    uploadNewFileVersion,
    deleteFile,
    linkExistingFileToSection,
    linkExistingFileToProduct,
    deleteFileLinkFromSection,
    deleteFileLinkFromProduct
  };
});
