<template>
  <Collapse
    :is-content-empty="activity.length === 0 && !addingMessage"
    :class="{ updating: isUpdating }"
  >
    <template v-slot:title>Activity</template>
    <template v-slot:content>
      <ul
        class="activity-log list-unstyled"
        data-testing="activity-log-container"
      >
        <li
          v-if="addingMessage && usersStore.currentUser"
          :class="{ updating: savingMessage }"
        >
          <small class="date" data-testing="blackout">
            {{ getDateString() }}
          </small>
          <small>{{ usersStore.currentUser.displayName }}:</small>
          <span
            data-testing="new-message-text"
            id="new-message"
            ref="new-message"
            class="new-message event-text"
            v-editable="true"
            @edited="saveMessage"
          ></span>
        </li>
        <li
          tabindex="0"
          class="message-container"
          :class="{
            updating: false
          }"
          data-testing="event"
          v-for="event in activity"
          :key="event.timeStamp"
        >
          <small class="date" data-testing="blackout">
            {{ event.displayDateTime }}
          </small>
          <div>
            <small v-if="event.userDisplayName"
              >{{ event.userDisplayName }}:
            </small>
            <span class="event-text">{{ event.text }}</span>
            <span v-if="event.type === 'file-attached'">
              <SVGIcon :path="mdiPaperclip" />
              <a :href="event.fileLocation" class="btn-3" target="_blank">
                {{ event.filename }}
              </a>
            </span>
            <button
              data-testing="delete-message"
              v-if="
                event.type === 'message' &&
                usersStore.currentUser.id === event.userId
              "
              @click="removeMessage(event.id)"
              v-on:blur="resetMessageDeletion()"
              :class="{ warning: messageBeingDeleted === event.id }"
              class="delete-message btn-3 float-right"
            >
              <small v-if="messageBeingDeleted === event.id">
                <SVGIcon :path="mdiCheck" :type="'is-danger'" />
                Confirm
              </small>
              <small v-else>
                <SVGIcon :path="mdiDelete" />
                Delete
              </small>
            </button>
          </div>
        </li>
      </ul>
      <div class="has-text-right">
        <button
          class="btn-3 float-right"
          v-if="enableShowMore"
          @click="toggleActivityLogShowMore()"
        >
          <span v-if="activityLogShowMore">
            <SVGIcon :path="mdiChevronUp" />
            Show fewer
          </span>
          <span v-else>
            <SVGIcon :path="mdiChevronDown" />
            Show more
          </span>
        </button>
      </div>
    </template>
    <template v-slot:footer>
      <footer class="card-footer">
        <div class="card-footer-item">
          <button
            class="btn-3"
            type="button"
            data-testing="add-message"
            @click="allowMessage()"
          >
            <SVGIcon :path="mdiPlus" />
            Add message
          </button>
        </div>
      </footer>
    </template>
  </Collapse>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatFullDate } from "@/utils/formatTimestamp";
import Collapse from "../ui/Collapse.vue";
import { WorkflowState } from "@/models/workflow.model";
import { OrganizationUser } from "@/models/user.model";
import {
  Message,
  SectionFile,
  StateTransition
} from "@/models/product-section.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import {
  mdiPlus,
  mdiChevronDown,
  mdiChevronUp,
  mdiDelete,
  mdiPaperclip,
  mdiCheck
} from "@mdi/js";
import { mapStores } from "pinia";
import { useUsersStore } from "@/stores/users.store";
import { useContentStore } from "@/stores/content.store";

interface Data {
  addingMessage: boolean;
  savingMessage: boolean;
  messageBeingDeleted: any;
  activityLogLimit: number;
  enableShowMore: boolean;
  activityLogShowMore: boolean;
  mdiPlus: string;
  mdiChevronDown: string;
  mdiChevronUp: string;
  mdiDelete: string;
  mdiPaperclip: string;
  mdiCheck: string;
}
interface Activity {
  displayDateTime: string;
  id: number;
  text: string;
  timestamp: number;
  type: string;
  userDisplayName: string;
  userId?: number;
  filename?: string;
  fileLocation?: string;
}

export default defineComponent({
  name: "ContentActivityLog",
  props: {
    isUpdating: {
      required: false,
      type: Boolean
    },
    section: {
      required: true,
      type: Object as PropType<ContentStructureSection>
    },
    sectionId: {
      required: true,
      type: Number
    },
    productId: {
      required: true,
      type: Number
    },
    workflowStates: {
      required: true,
      type: Array as PropType<WorkflowState[]>
    }
  },
  components: { Collapse },
  data(): Data {
    return {
      addingMessage: false,
      savingMessage: false,
      messageBeingDeleted: undefined,
      activityLogLimit: 5,
      enableShowMore: false,
      activityLogShowMore: false,
      mdiPlus,
      mdiChevronDown,
      mdiChevronUp,
      mdiDelete,
      mdiPaperclip,
      mdiCheck
    };
  },
  computed: {
    ...mapStores(useUsersStore, useContentStore),
    activity(): Activity[] {
      const messages: Activity[] = this.section.messages.map(
        (message: Message): Activity => {
          const user = this.usersStore.users?.find(
            (e: OrganizationUser): boolean => e.id === message.user_id
          );
          return {
            displayDateTime: formatFullDate(message.updated_on_millis),
            timestamp: message.updated_on_millis,
            userDisplayName: user ? user.displayName : "",
            userId: message.user_id,
            text: message.message,
            type: "message",
            id: message.id
          };
        }
      );
      const transitions: StateTransition[] =
        this.section.state_transitions.filter(
          (transition: StateTransition): boolean => {
            const newState: WorkflowState | undefined =
              this.workflowStates.find(
                (e: WorkflowState): boolean => e.id === transition.state_id
              );
            return !!newState;
          }
        );
      const transitionActivities = transitions.map(
        (tEvent: StateTransition): Activity => {
          const user: OrganizationUser | undefined =
            this.usersStore.users?.find(
              (e: OrganizationUser): boolean => e.id === tEvent.actor
            );
          const currentWorkflowState: WorkflowState | undefined =
            this.workflowStates.find(
              (e: WorkflowState): boolean => e.id === tEvent.state_id
            );
          const newStateName: string =
            currentWorkflowState && currentWorkflowState.name
              ? currentWorkflowState.name
              : "";
          return {
            displayDateTime: formatFullDate(tEvent.created_on_millis),
            timestamp: tEvent.created_on_millis,
            userDisplayName: user ? user.displayName : "",
            text: "Moved to " + newStateName,
            type: "transition",
            id: tEvent.id
          };
        }
      );
      const fileAttachments: Activity[] = this.section.files.map(
        (file: SectionFile): Activity => {
          const user: OrganizationUser | undefined =
            this.usersStore.users?.find(
              (e: OrganizationUser): boolean => e.id === file.link_created_by
            );
          return {
            displayDateTime: formatFullDate(file.link_created_on),
            timestamp: file.link_created_on,
            userDisplayName: user ? user.displayName : "",
            userId: file.link_created_by,
            text: "",
            filename: file.name,
            fileLocation: file.location,
            type: "file-attached",
            id: file.file_to_section_id
          };
        }
      );
      const fileRemovals: Activity[] = this.section.files
        .filter((e: SectionFile): boolean => e.link_deleted_on)
        .map((file: SectionFile) => {
          const user = this.usersStore.users?.find(
            (e: OrganizationUser): boolean => e.id === file.link_created_by
          );
          return {
            displayDateTime: formatFullDate(
              file.link_deleted_on as unknown as number
            ),
            timestamp: file.link_deleted_on
              ? (file.link_deleted_on as unknown as number)
              : 0,
            userDisplayName: user ? user.displayName : "",
            userId: file.link_deleted_by
              ? (file.link_deleted_by as unknown as number)
              : 0,
            text: "Removed file: " + file.name,
            filename: file.name,
            fileLocation: file.location,
            type: "file-deleted",
            id: file.file_to_section_id
          };
        });
      const componentAdditions: Activity[] = this.section.sections.map(
        (section: ContentStructureSection): Activity => {
          const user = this.usersStore.users?.find(
            (e: OrganizationUser): boolean => e.id === section.created_by
          );
          return {
            displayDateTime: formatFullDate(section.created_on_millis),
            timestamp: section.created_on_millis,
            userDisplayName: user ? user.displayName : "",
            userId: section.created_by,
            text: "Added " + section.type.name + ": " + section.name,
            type: "component-added",
            id: section.id
          };
        }
      );
      let activityLogArr: Activity[] = messages
        .concat(transitionActivities)
        .concat(fileAttachments)
        .concat(fileRemovals)
        .concat(componentAdditions)
        .sort((a: Activity, b: Activity): number => {
          return b.timestamp - a.timestamp;
        });
      this.enableActivityLogShowMore(activityLogArr);
      return !this.activityLogShowMore
        ? activityLogArr.slice(0, this.activityLogLimit)
        : activityLogArr;
    }
  },
  methods: {
    getDateString(): string {
      return formatFullDate(Date.now());
    },
    allowMessage(): void {
      this.addingMessage = true;
      this.$nextTick(function () {
        (this.$refs["new-message"] as HTMLElement).focus();
      });
    },
    saveMessage(event: CustomEvent<{ textContent: string }>): void {
      const message = event.detail.textContent;
      this.savingMessage = true;
      this.contentStore
        .submitMessage({
          productId: this.productId,
          sectionId: this.sectionId,
          message: message
        })
        .then((): void => {
          this.addingMessage = false;
          this.savingMessage = false;
        });
    },
    removeMessage(messageId: number): void {
      if (!this.messageBeingDeleted) {
        this.messageBeingDeleted = messageId;
      } else if (this.messageBeingDeleted === messageId) {
        this.contentStore.deleteMessage({
          messageId: messageId,
          productId: this.productId
        });
      }
    },
    resetMessageDeletion(): void {
      this.messageBeingDeleted = undefined;
    },
    enableActivityLogShowMore(arr: Activity[]): void {
      this.enableShowMore = arr.length > this.activityLogLimit;
    },
    toggleActivityLogShowMore(): void {
      this.activityLogShowMore = !this.activityLogShowMore;
    }
  }
});
</script>
<style lang="scss" scoped>
li {
  list-style: none;
  padding-bottom: 0.1rem;
  font-size: 0.875rem;
}
button {
  .plus-icon {
    color: $primary-color;
  }
  &:focus .plus-icon,
  &:hover .plus-icon {
    color: $primary-color-light;
  }
  &:hover[title]::after {
    white-space: pre;
  }
}
.activity-log {
  overflow-y: auto;
  max-height: calc(100vh - 25rem);
  margin: 0;
}
.delete-message {
  display: none;
  background-color: $background-highlight-color;
  padding: 0 0.25rem;
}
.new-message.event-text {
  display: inline-block;
  min-width: 1rem;
}
.message-container {
  display: flex;
  position: relative;
  &:hover,
  &:focus,
  &:focus-within {
    background-color: $background-highlight-color;
    outline: 0;
    .delete-message {
      display: initial;
      position: absolute;
      top: 0;
      right: 0.25rem;
    }
  }
}
.date {
  display: inline-block;
  min-width: 5.25rem; //fixed width for visual test blackout
  padding-right: 0.25rem;
}
</style>
