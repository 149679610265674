<template>
  <div>
    <table class="simple-table mb-0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th>Date Added</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <FilesListItem
          v-for="file in filesToDisplay"
          :key="file.id ? file.id : file.location"
          :file="file"
          :is-attachment-table="isAttachmentsTable"
          :hasExternalContentAuthoring="hasExternalContentAuthoring"
          :is-set-as-media-content="
            isFileSetAsMediaContent(file.location, file.id)
          "
          @launchRenamePopup="launchRenamePopup"
          @openUploadVersionModal="openUploadVersionModal"
          @showFileInLibrary="showFileInLibrary"
          @launchRemoveAttachmentDialog="launchRemoveAttachmentDialog"
          @setAsMediaContent="
            setAsMediaContent(file.location, file.id, section)
          "
          @unsetAsMediaContent="unsetAsMediaContent(section)"
          @previewAttachment="previewAttachment"
        />
      </tbody>
    </table>

    <b-modal
      :model-value="uiFilesStore.isFileVersionModalOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiFilesStore.closeAttachmentVersionModal"
    >
      <UploadFileVersion
        :file="fileForNewVersion"
        :productId="section.product_id"
      />
    </b-modal>

    <b-modal
      :model-value="uiFilesStore.isRemoveAttachmentPopupOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiFilesStore.closeRemoveAttachmentModal"
    >
      <RemoveAttachment
        :file="fileForRemoveLink"
        :section="section"
        :shouldBeUnsetAsMediaContent="
          fileForRemoveLink &&
          isFileSetAsMediaContent(
            fileForRemoveLink.location,
            fileForRemoveLink.id
          )
        "
      />
    </b-modal>

    <b-modal
      :model-value="uiFilesStore.isRenameAttachmentPopupOpen"
      :file="fileForRenaming"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiFilesStore.openRenameAttachmentModal"
    >
      <RenameAttachment :file="fileForRenaming" />
    </b-modal>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { defineComponent, PropType } from "vue";
import FilesMixin from "@/mixins/files";
import cnst from "@/utils/constants";
import FilesListItem from "@/components/right-sidebars/content-sidebar/authoring-tool/FilesListItem.vue";
import UploadFileVersion from "@/components/popups/attachments/UploadFileVersion.vue";
import RemoveAttachment from "@/components/popups/attachments/RemoveAttachment.vue";
import RenameAttachment from "@/components/popups/attachments/RenameAttachment.vue";
import { LibraryFile } from "@/models/files.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFilesStore } from "@/stores/files.store";

interface Data {
  fileForNewVersion: SectionFile | LibraryFile | undefined;
  fileForRemoveLink: SectionFile | undefined;
  fileForRenaming: LibraryFile | undefined;
}
interface UnavailableFile {
  name: string;
  location: string;
  is_external: boolean;
}

export default defineComponent({
  name: "FilesList",
  mixins: [FilesMixin],
  data: (): Data => {
    return {
      fileForNewVersion: undefined,
      fileForRemoveLink: undefined,
      fileForRenaming: undefined
    };
  },
  props: {
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    },
    filesToDisplay: {
      required: true,
      type: Array as PropType<SectionFile[] | LibraryFile[] | UnavailableFile[]>
    },
    isAttachmentsTable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  components: {
    FilesListItem,
    UploadFileVersion,
    RemoveAttachment,
    RenameAttachment
  },
  computed: {
    ...mapStores(useUIFilesStore, useFilesStore),
    hasExternalContentAuthoring(): boolean {
      return cnst.isExternalContentAuthoringToolId(
        this.section && this.section.type.tool_id
      );
    }
  },
  methods: {
    openUploadVersionModal(file: SectionFile | LibraryFile): void {
      this.fileForNewVersion = file;
      this.uiFilesStore.openAttachmentVersionModal();
    },
    launchRenamePopup(file: SectionFile | LibraryFile): void {
      this.fileForRenaming = this.getFileObject(file.id);
      if (this.fileForRenaming) {
        this.uiFilesStore.openRenameAttachmentModal();
      }
    },
    launchRemoveAttachmentDialog(file: SectionFile): void {
      this.fileForRemoveLink = file;
      this.uiFilesStore.openRemoveAttachmentModal();
    },
    showFileInLibrary(file: SectionFile | LibraryFile): void {
      const validURLFileName = file.name.split(".")[0];
      const query = {
        filters: `name--${validURLFileName}--default`,
        sidebar: `attachment-${file.id}`
      };

      this.$router.push({
        path: "/attachments",
        query: query
      });
    },
    getFileObject(fileId: number): LibraryFile | undefined {
      if (this.filesStore.files) {
        return this.filesStore.files.find(
          (f: LibraryFile): boolean => f.id === fileId
        );
      }
      return undefined;
    },
    isFileSetAsMediaContent(fileLocation: string, fileId: number): boolean {
      return (
        this.hasExternalContentAuthoring &&
        !!this.section.authored_content &&
        (fileId ===
          this.section.authored_content.externalContent.attachmentId ||
          fileLocation === this.section.authored_content.externalContent)
      );
    },
    previewAttachment(fileLocation: string): void {
      this.$emit("previewAttachment", fileLocation);
    }
  }
});
</script>
<style scoped lang="scss"></style>
