import { ref, computed } from "vue";
import { useOrganizationStore } from "@/stores/organization.store";

const IS_PRODUCTION = process.env.NODE_ENV === "production";
const ORGANIZATION_IDS = {
  PRODUCTION: {
    ADAPTEMY_TEST: 1,
    ADAPTEMY_DEVELOPMENT: 2,
    ADAPTEMY: 3,
    FOLENS: 4,
    DIE_KEURE: 5,
    ICAR: 17
  }
};

export const useFeatureFlags = () => {
  const orgStore = useOrganizationStore();

  const ffIsProduction = ref<boolean>(IS_PRODUCTION);

  const ffShouldTaskNotificationsBeSent = computed<boolean>(() => {
    return ffIsProduction.value;
  });

  const ffIsFolensOrganization = computed<boolean>(() => {
    return (
      ffIsProduction.value &&
      !!orgStore.organization &&
      ORGANIZATION_IDS.PRODUCTION.FOLENS === orgStore.organization.id
    );
  });
  const ffIsIcarOrganization = computed<boolean>(() => {
    return (
      !!orgStore.organization &&
      ORGANIZATION_IDS.PRODUCTION.ICAR === orgStore.organization.id
    );
  });

  const hasDeployButton = (isQTI: boolean, productId: number): boolean => {
    return isQTI && productId === 17 && ffIsProduction.value;
  };

  return {
    ffIsProduction,
    ffShouldTaskNotificationsBeSent,
    ffIsFolensOrganization,
    ffIsIcarOrganization,
    hasDeployButton
  };
};
