<template>
  <router-link
    :to="link.to"
    :class="[
      'sidebar-link',
      {
        highlighted: link.isHighlighted,
        'not-available': link.isNotAvailable,
        open: uiGeneralStore.isSidebarOpen
      }
    ]"
    :data-testing="link.dataTesting"
  >
    <span class="sidebar-icon" :title="link.title">
      <slot></slot>
    </span>
    <span class="link-text">{{ link.title }}</span>
  </router-link>
</template>
<script lang="ts" setup>
import { PropType } from "vue";
import { SidebarNavigationLink } from "@/models/sidebar.model";
import { useUIGeneralStore } from "@/stores/ui-general.store";

const uiGeneralStore = useUIGeneralStore();

defineProps({
  link: {
    required: true,
    type: Object as PropType<SidebarNavigationLink>
  }
});
</script>
<style lang="scss">
$transition-speed: 0.3s;
$link-font-size: 1.125rem;
.sidebar-link {
  overflow: hidden;
  padding: 0.185rem 1rem 0.185rem 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none !important;
  background-color: transparent;
  transition: background-color $transition-speed ease-in-out,
    width $transition-speed ease-in-out;
  width: $sidebar-width-sm;
  height: 2.5rem;
  &.open {
    width: $sidebar-width;
    @media (max-width: $breakpoint-md) {
      width: $sidebar-width-md;
    }
  }
  .sidebar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: $sidebar-width-sm;
    text-align: center;
  }
  .sidebar-icon svg {
    fill: white;
    stroke: white;
    height: calc(#{$link-font-size} + 0.1rem);
    path {
      transition: fill $transition-speed ease-in-out,
        stroke $transition-speed ease-in-out;
    }
  }
  .link-text {
    font-size: $link-font-size;
    transition: all $transition-speed ease-in-out;
  }
  &.highlighted,
  &.router-link-exact-active {
    background: white;
  }
  &.highlighted,
  &.router-link-exact-active,
  &:focus .link-text {
    color: $blue100;
    font-weight: bold;
  }
  &:hover .link-text {
    color: $blue100;
  }
  &.highlighted .sidebar-icon svg *,
  &.router-link-exact-active .sidebar-icon svg *,
  &:hover .sidebar-icon svg *,
  &:focus .sidebar-icon svg * {
    fill: $blue100;
    stroke: $blue100;
  }
}
</style>
