import { defineStore } from "pinia";
import API from "@/api";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import {
  ContentToDelete,
  MessageToAdd,
  MessageToDelete,
  SectionsToUpsert,
  StateTransitionToSubmit,
  UpsertResponse
} from "@/models/api/content.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import { DocumentToUpdateStore } from "@/models/api/document.model";

export const useContentStore = defineStore("content", () => {
  const productsStore = useProductsStore();
  const contStrStore = useContentStructureStore();

  const deleteSection = (payload: ContentToDelete) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.content.remove(payload.sectionsId).then(() => {
      return productsStore.populateProductDetails(payload.productId);
    });
  };
  const upsertSections = (payload: SectionsToUpsert) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.content
      .upsert(payload.sections)
      .then((response: AxiosResponse<UpsertResponse>) => {
        return response;
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error(error.message);
        }
      })
      .finally(() => {
        payload.productIds.forEach((id: number) => {
          return productsStore.populateProductDetails(id);
        });
      });
  };

  const submitMessage = (payload: MessageToAdd) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    const productId = payload.productId;
    const apiPayload = {
      section_id: payload.sectionId,
      data: payload.message
    };
    return API.content.createMessage(apiPayload).then(() => {
      return productsStore.populateProductDetails(productId);
    });
  };
  const deleteMessage = (params: MessageToDelete) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.content.removeMessage(params.messageId).then(() => {
      return productsStore.populateProductDetails(params.productId);
    });
  };

  const submitStateTransition = (payload: StateTransitionToSubmit) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    const productId = payload.productId;
    const apiPayload = {
      state_id: payload.stateId,
      sections_id: payload.sectionId,
      users_to_notify: payload.users_to_notify,
      status_after: payload.status ? payload.status.statusAfter : "",
      status_before: payload.status ? payload.status.statusBefore : "",
      task_name: payload.status ? payload.status.taskName : ""
    };
    return API.content
      .updateStateTransition(apiPayload)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error(error.message);
        }
      })
      .finally(() => {
        // to reduce the number of get product requests during bulk editing
        if (payload.shouldProductBeUpdated) {
          return productsStore.populateProductDetails(productId);
        }
      });
  };

  const updateDocument = (payload: DocumentToUpdateStore) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.document
      .update({ section_id: payload.section_id, document: payload.document })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error(error.message);
        }
      })
      .finally(() => {
        return productsStore.populateProductDetails(payload.product_id);
      });
  };

  return {
    deleteSection,
    upsertSections,
    submitMessage,
    deleteMessage,
    submitStateTransition,
    updateDocument
  };
});
