// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import cnst from "@/utils/constants";
import { ProductFeature, ProductItem } from "@/models/product.model";
import { mapStores } from "pinia";
import { useProductsStore } from "@/stores/products.store";

const UserInterfaceFeatureFlaggerMixin = {
  computed: {
    ...mapStores(useProductsStore)
  },
  methods: {
    getProduct(productId: number): ProductItem | undefined {
      if (this.productsStore.areProductDetailsLoading || !productId) {
        return undefined;
      }
      return this.productsStore.productDetails?.find(
        (product: ProductItem): boolean => {
          return product.id === Number(productId);
        }
      );
    },
    getAppropriateFeature(
      productId: number,
      featureId: number
    ): ProductFeature | undefined {
      const product = this.getProduct(productId);
      if (product) {
        return product.features.find((feature: ProductFeature): boolean => {
          return feature.id === featureId;
        });
      }
      return undefined;
    },
    isAssetManagementFeatureEnabled(productId: number): boolean {
      const appropriateFeature = this.getAppropriateFeature(
        productId,
        cnst.userInterfaceFeatures.assetManagementTier2.id
      );
      return !!appropriateFeature && appropriateFeature.isEnabled;
    },
    isWorkflowManagementFeatureEnabled(productId: number): boolean {
      const appropriateFeature = this.getAppropriateFeature(
        productId,
        cnst.userInterfaceFeatures.workflowManagement.id
      );
      return !!appropriateFeature && appropriateFeature.isEnabled;
    },
    isContentAuthoringTier1FeatureEnabled(productId: number): boolean {
      const appropriateFeature = this.getAppropriateFeature(
        productId,
        cnst.userInterfaceFeatures.contentAuthoringTier1.id
      );
      return !!appropriateFeature && appropriateFeature.isEnabled;
    },
    isContentAuthoringTier2FeatureEnabled(productId: number): boolean {
      const appropriateFeature = this.getAppropriateFeature(
        productId,
        cnst.userInterfaceFeatures.contentAuthoringTier2.id
      );
      return !!appropriateFeature && appropriateFeature.isEnabled;
    }
  }
};
export default UserInterfaceFeatureFlaggerMixin;
