<template>
  <div class="modal-card" data-testing="remove-attachment-card">
    <div class="modal-card-header">
      <h3>
        Remove attachment <i>{{ file.name }}</i>
      </h3>
    </div>
    <div class="modal-card-content">
      <p>Removing this attachment will remove it from this piece of content</p>
      <p v-if="shouldBeUnsetAsMediaContent"
        >Removing this attachment from the list will also unset it as the media
        content</p
      >
      <p>The attachment will still be available in the Attachments library</p>
      <p
        >To permanently delete this attachment, it must be deleted it from the
        <router-link class="btn-3" :to="{ name: 'files' }"
          >Attachment library</router-link
        ></p
      >
    </div>
    <div class="modal-card-footer">
      <button
        data-testing="remove-attachment-btn"
        class="btn-1 full-width margin-t-standard"
        @click="removeAttachment"
      >
        Remove
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from "vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";

const uiFilesStore = useUIFilesStore();
const { unlinkFileFromSection } = useFileManager();

const props = defineProps({
  file: {
    required: true,
    type: Object as PropType<SectionFile>
  },
  section: {
    type: Object as PropType<ContentStructureSection>,
    required: true
  },
  shouldBeUnsetAsMediaContent: {
    required: false,
    default: false,
    type: Boolean
  }
});

const removeAttachment = (): void => {
  unlinkFileFromSection({
    productId: props.section.product_id,
    linkId: props.file.file_to_section_id
  });
  uiFilesStore.closeRemoveAttachmentModal();
};
</script>
<style lang="scss" scoped>
.modal-card {
  max-width: 500px;
  width: auto;
  div p {
    margin-bottom: 0;
  }
}
</style>
