<template>
  <div class="rename-file modal-card" data-testing="rename-file-card">
    <div class="modal-card-header">
      <h3> Rename attachment </h3>
    </div>
    <form
      id="rename-attachment"
      class="modal-card-content"
      @submit.prevent="rename"
    >
      <label class="margin-b-standard">
        Enter a new attachment name
        <input
          v-model.trim="newName"
          type="text"
          data-testing="rename-file-input"
        />
      </label>
    </form>
    <div class="modal-card-footer">
      <button
        type="submit"
        data-testing="rename-file-btn"
        class="btn-1 full-width"
        form="rename-attachment"
        :disabled="!isSubmitBtnEnabled"
      >
        Rename
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, PropType, computed, onMounted, watch } from "vue";
import { LibraryFile } from "@/models/files.model";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useFileManager } from "@/composables/files-manager";

const uiFilesStore = useUIFilesStore();
const { renameAttachment } = useFileManager();

const props = defineProps({
  file: {
    type: Object as PropType<LibraryFile>,
    required: true
  }
});

const newName = ref<string>("");
onMounted(() => {
  if (props.file) {
    newName.value = props.file.name;
  }
});
watch(() => props.file, (newValue) => {
  if (newValue) {
    newName.value = newValue.name;
  }
});
const isSubmitBtnEnabled = computed<boolean>(() => {
  return newName.value !== props.file.name && newName.value !== "";
});
const rename = () => {
  renameAttachment(
    {
      id: props.file.id,
      name: newName.value
    },
    props.file
  );
  uiFilesStore.closeRenameAttachmentModal();
};
</script>
<style lang="scss" scoped>
.rename-file {
  width: auto;
}
form {
  min-width: 23rem;
}
</style>
