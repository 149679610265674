<template>
  <div
    id="login"
    class="floating-main-content-page"
    :style="{
      backgroundImage: 'url(' + require('@/assets/login-bg-image.jpg') + ')'
    }"
  >
    <div class="floating-main-content card-m-v no-border lighter-color">
      <h1 class="title">THOTH</h1>
      <form class="padding-double" @submit.prevent="attemptLogin()">
        <template v-if="teamsUserName">
          <h2 style="text-align: center">Hi! {{ teamsUserName }}</h2>
          <p style="text-align: center"
            >Authorization
            <span class="icon"><i class="mdi mdi-loading mdi-36px"></i></span
          ></p>
        </template>
        <template v-else>
          <label class="margin-b-standard">
            Username
            <input
              data-testing="username"
              required
              v-autofocus
              name="username"
              v-model="username"
            />
          </label>
          <label class="margin-b-double">
            Password
            <input
              data-testing="password"
              required
              type="password"
              name="password"
              v-model="password"
            />
          </label>
          <button
            data-testing="submit-login"
            :disabled="disableLoginButton"
            class="btn-1 center-horizontally margin-b-half"
            type="submit"
          >
            Log in
          </button>
          <small
            data-testing="error-message"
            class="error center-text grid-12"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </small>
        </template>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import TeamsMixin from "@/mixins/teams";
import { TrackJS } from "trackjs";
import GetTasksQueryMixin from "@/mixins/get-tasks-query";
import { AxiosResponse } from "axios";
import cnst from "@/utils/constants";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUsersStore } from "@/stores/users.store";
import { useAuthStore } from "@/stores/auth.store";

interface Data {
  username: string;
  password: string;
  disableLoginButton: boolean;
  errorMessage: string;
  teamsUserName: string;
}

export default defineComponent({
  data(): Data {
    return {
      username: "",
      password: "",
      disableLoginButton: false,
      errorMessage: "",
      teamsUserName: ""
    };
  },
  mixins: [TeamsMixin, GetTasksQueryMixin],
  created() {
    this.attemptTeamsSSO(this.proceedAfterLogin);
  },
  computed: {
    ...mapStores(useUsersStore, useAuthStore)
  },
  methods: {
    attemptLogin(): void {
      this.errorMessage = "";
      this.disableLoginButton = true;
      this.authStore
        .login({ username: this.username, password: this.password })
        .then(async (response: AxiosResponse | undefined): Promise<void> => {
          if (response?.status === 201) {
            await this.populateOrganizationData();
            this.populateStoreWithGeneralData();

            if (this.usersStore.currentUser) {
              TrackJS.configure({
                userId: `${this.usersStore.currentUser.id}`
              });
              TrackJS.addMetadata("logged-in-via", "browser");
            }
            this.proceedAfterLogin();
          } else {
            this.errorMessage = cnst.loginError;
            this.disableLoginButton = false;
          }
        })
        .catch((error: { status: number; data: { message: any } }): void => {
          if (error.status === 400) {
            this.errorMessage = error.data.message;
            this.disableLoginButton = false;
          } else {
            this.errorMessage = cnst.unexpectedError;
          }
        })
        .finally((): void => {
          this.disableLoginButton = false;
        });
    },
    proceedAfterLogin(): void {
      this.$router.push({
        name: "home",
        query: this.getTasksQuery() as unknown as Record<string, string>
      });
    }
  }
});
</script>

<style lang="scss" scoped>
#login {
  height: 100vh;
  label {
    color: $grey80;
    font-size: 0.8rem;
    input {
      font-size: 1rem;
    }
  }
  h1 {
    font-size: 1.25rem;
    padding: 3rem 2rem 1rem;
    font-weight: 800;
    color: $blue100;
  }
  .floating-main-content {
    width: 100%; //rely on global style to limit width of container
    @media (max-width: $breakpoint-md) {
      height: 100%;
    }
  }
  @media (min-width: $breakpoint-md) {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .padding-double {
    padding: 2rem;
  }
  .mdi-loading {
    animation: spin 2s infinite linear;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
