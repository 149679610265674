import { useUsersStore } from "@/stores/users.store";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import { TaskDisplay, TaskDisplayData } from "@/models/task.model";
import {
  ContentStructureSection,
  GeneratedContentStructure
} from "@/models/content-structure.model";
import { ProductItem } from "@/models/product.model";
import csHelper from "@/utils/product/content-structure-helpers";
import cnst from "@/utils/constants";
import {
  addItemForEachWorkflowState,
  generateTaskForTable,
  orderDisplaySectionByDeadline
} from "@/utils/tasks-helpers";
import { computed, ref } from "vue";
import { isAValidPathToTheLocalFile } from "@/utils/file-helpers";
import { ReportTableColumn } from "@/models/report-table.model";
import { useUIContentStore } from "@/stores/ui-content.store";

export const useTasksManager = () => {
  const usersStore = useUsersStore();
  const productsStore = useProductsStore();
  const contStrStore = useContentStructureStore();
  const uiContentStore = useUIContentStore();

  const getTasks = (): TaskDisplay[] => {
    return contStrStore.contentStructure.reduce(
      (acc: TaskDisplay[], contentStructure: GeneratedContentStructure) => {
        const product = productsStore.productDetails?.find(
          (e: ProductItem) => e.id === contentStructure.product_id
        );
        const users = usersStore.users;
        if (product && users) {
          const allSections: TaskDisplayData[][] = csHelper
            .getAllSections(contentStructure)
            .filter((e: ContentStructureSection) => {
              return cnst.contentTemplates.isAContentObject(e.type.category);
            })
            .map((section: ContentStructureSection) => {
              return addItemForEachWorkflowState(product, section);
            });

          const tasksForTable: TaskDisplay[] = [];
          allSections.forEach((tasks: TaskDisplayData[]) => {
            tasks
              .filter((task: TaskDisplayData) => {
                return !task.workflowState.isFinalState;
              })
              .forEach((task: TaskDisplayData) => {
                tasksForTable.push(generateTaskForTable(task, product, users));
              });
          });
          return acc.concat(tasksForTable);
        }
        return acc;
      },
      []
    );
  };
  const getTasksByProduct = (productId: number): TaskDisplay[] => {
    return getTasks().filter((task: TaskDisplay) => {
      return task.product.id === productId;
    });
  };

  const areAllTasksLoading = computed<boolean>(() => {
    return contStrStore.isContentStructureLoading;
  });
  const allTasks = computed<TaskDisplay[]>(() => {
    const tasks = getTasks();
    return tasks
      .filter((task: TaskDisplay) => {
        return task.product.status !== "Archive";
      })
      .filter((task: TaskDisplay) => {
        return (
          task.product.allocated_permissions.length > 0 ||
          (!!usersStore.currentUser &&
            task.assignedUsersList.includes(usersStore.currentUser.displayName))
        );
      })
      .sort(orderDisplaySectionByDeadline);
  });

  const timeoutId = ref<number | null>(null);
  const clearExistingTimeout = () => {
    if (timeoutId.value !== null) {
      clearTimeout(timeoutId.value);
      timeoutId.value = null;
    }
  };

  const openSidebarWithoutPreview = (task: TaskDisplay): void => {
    uiContentStore.setFileLocationToPreview(undefined);
    uiContentStore.openContentSidebar({
      sectionId: task.id,
      productId: task.product.id,
      workflowStateId: task.workflowStateId,
      hasCloseButton: true
    });
  };
  const openInternalFile = (task: TaskDisplay, location: string): void => {
    uiContentStore.openContentSidebar({
      sectionId: task.id,
      productId: task.product.id,
      workflowStateId: task.workflowStateId,
      hasCloseButton: true
    });
    clearExistingTimeout();
    timeoutId.value = window.setTimeout(() => {
      uiContentStore.setFileLocationToPreview(location);
    }, 150);
  };
  const openExternalFile = (location: string): void => {
    uiContentStore.closeContentSidebar();
    window.open(location, "_blank");
  };
  const handleSingleFile = (task: TaskDisplay): void => {
    const file = task.files[0];
    const isExternal = !!file.is_external;
    const isLocalFile = isAValidPathToTheLocalFile(file.location);

    if (isExternal && !isLocalFile) {
      openExternalFile(file.location);
    } else if (!isExternal || (isExternal && isLocalFile)) {
      openInternalFile(task, file.location);
    } else {
      openSidebarWithoutPreview(task);
    }
  };
  const clickHandler = (task: TaskDisplay): void => {
    if (task.files.length === 1) {
      handleSingleFile(task);
    } else {
      openSidebarWithoutPreview(task);
    }
  };
  const reportColumns = computed<ReportTableColumn<TaskDisplay>[]>(() => {
    return [
      {
        field: "typeIcon",
        label: "Type",
        altText: "typeName",
        isSectionIcon: true,
        narrowFilter: true,
        dropdownFilter: true
      },
      {
        field: "name",
        label: "Task",
        altText: "nameString",
        isHtml: true,
        click: (section: TaskDisplay) => {
          uiContentStore.openContentSidebar({
            sectionId: section.id,
            productId: section.product.id,
            workflowStateId: section.workflowStateId,
            hasCloseButton: true
          });
        }
      },
      {
        field: "attachments",
        label: "Attachments",
        altText: "attachmentsString",
        hideFilter: true,
        isIcon: true,
        isHtml: true,
        click: (task: TaskDisplay) => clickHandler(task)
      },
      {
        field: "responsible",
        label: "Assigned to",
        isHtml: true,
        altText: "responsibleString"
      },
      { field: "deadline", label: "Deadline", date: true },
      { field: "productName", label: "Product" },
      {
        field: "statusWithIcon",
        label: "Status",
        altText: "statusString",
        isIcon: true,
        isHtml: true,
        dropdownMultipleFilter: true
      }
    ];
  });

  return {
    getTasks,
    getTasksByProduct,
    areAllTasksLoading,
    allTasks,
    timeoutId,
    openSidebarWithoutPreview,
    clearExistingTimeout,
    openInternalFile,
    handleSingleFile,
    clickHandler,
    reportColumns
  };
};
