// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  generateMultipleFilter,
  generateFilterQuery
} from "@/utils/report-table-helpers";
import {
  ReportTableComparison,
  ReportTableFilterExtended,
  ReportTableState
} from "@/models/report-table.model";
import { TaskStatus } from "@/models/task.model";
import { mapStores } from "pinia";
import { useUsersStore } from "@/stores/users.store";

const GetTasksQueryMixin = {
  computed: {
    ...mapStores(useUsersStore)
  },
  methods: {
    getTasksQuery(): ReportTableState {
      const queryRow = generateFilterQuery([
        {
          field: "responsible",
          value: this.usersStore.currentUser?.displayName ?? "",
          comparison: ReportTableComparison.DEFAULT
        },
        ...this.getTaskStatusesQuery()
      ]);
      return { filters: queryRow };
    },
    getTaskStatusesQuery(): ReportTableFilterExtended[] {
      const statuses = Object.values(TaskStatus).filter(status => {
        return (
          status !== TaskStatus.FINAL_STATE && status !== TaskStatus.COMPLETE
        );
      });
      return generateMultipleFilter(
        "statusWithIcon",
        statuses,
        ReportTableComparison.MATCHES
      );
    }
  }
};
export default GetTasksQueryMixin;
