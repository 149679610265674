import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-383d2a9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttachFile = _resolveComponent("AttachFile")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_AddMetadata = _resolveComponent("AddMetadata")!
  const _component_AddProductImage = _resolveComponent("AddProductImage")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ContentSidebar = _resolveComponent("ContentSidebar")!
  const _component_FileSidebar = _resolveComponent("FileSidebar")!
  const _component_CreateSection = _resolveComponent("CreateSection")!
  const _component_ToggleEditMode = _resolveComponent("ToggleEditMode")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'main-layout',
      { 'right-sidebar-padding': _ctx.uiGeneralStore.isRightSidebarOpen }
    ])
  }, [
    _createVNode(_component_b_modal, {
      "model-value": _ctx.uiFilesStore.isAttachFileModalOpen,
      "has-modal-card": true,
      "trap-focus": true,
      "aria-role": "dialog",
      "aria-modal": true,
      onCancel: _ctx.uiFilesStore.closeAttachFileModal,
      class: "full-screen-modal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AttachFile)
      ]),
      _: 1
    }, 8, ["model-value", "onCancel"]),
    _createVNode(_component_b_modal, {
      "model-value": _ctx.uiContentTemplatesStore.isAddMetadataModalOpen,
      "has-modal-card": true,
      "trap-focus": true,
      "aria-role": "dialog",
      "aria-modal": true,
      onCancel: _ctx.uiContentTemplatesStore.closeAddMetadataModal,
      class: "full-screen-modal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AddMetadata)
      ]),
      _: 1
    }, 8, ["model-value", "onCancel"]),
    _createVNode(_component_b_modal, {
      "model-value": _ctx.uiProductsStore.isProductImageModalVisible,
      "has-modal-card": true,
      "trap-focus": true,
      "aria-role": "dialog",
      "aria-modal": true,
      onCancel: _ctx.uiProductsStore.closeProductImageModal,
      class: "full-screen-modal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AddProductImage)
      ]),
      _: 1
    }, 8, ["model-value", "onCancel"]),
    _createVNode(_component_Sidebar),
    _createVNode(_component_ContentSidebar),
    _createVNode(_component_FileSidebar),
    _createVNode(_component_CreateSection),
    _createElementVNode("div", null, [
      _createVNode(_component_ToggleEditMode),
      _createVNode(_component_Header),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 2))
}