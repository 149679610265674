import cnst from "../constants";
import { AuthoringTools } from "@/models/constants.model";
import { ProductItem } from "@/models/product.model";
import {
  ContentTemplate,
  MetadataAllowedValues,
  MetadataDefinitionItem
} from "@/models/content-templates.model";
import {
  XAPI,
  QuestionObj,
  ExtensionsValue,
  Question,
  ParsedAuthoredContent
} from "@/models/x-api.model";
import { convertToJsonOrNull } from "../utils";
import { isAValidURL } from "../file-helpers";
const DOMParser = require("xmldom").DOMParser;
import { buildQuestionsHTMLArray } from "@/utils/product/qti-to-html-helper";
import { XAPIError, XAPIPreError, XAPIErrors } from "@/models/x-api.model";
import { QTIQuestionHTML } from "@/models/qti.model";
import {
  MetadataAllocatedValuesItem,
  MetadataInstance,
  ProductSection,
  SectionFile,
  SectionConcepts
} from "@/models/product-section.model";
import {
  ExternalContent,
  ExternalContentAttachment
} from "@/models/api/content.model";

const hasNameSpace = (namespace: string): boolean => {
  return Boolean(namespace);
};
const validateBuildXAPI = async (product: ProductItem): Promise<XAPIErrors> => {
  const contentErrors = getContentErrors(product.sections);
  const contentTemplatesErrors = getContentTemplatesErrors(product);
  const contentWarnings = await getContentWarnings(product.sections);
  return {
    contentErrors,
    contentTemplatesErrors,
    contentWarnings
  };
};

const buildXAPI = async (
  product: ProductItem,
  conceptsArray: string[]
): Promise<undefined | XAPI[]> => {
  let isValid: boolean = true;

  const xAPIJSON: XAPI[] | undefined = await product.sections.reduce(
    async (accP: Promise<Array<XAPI> | undefined>, section: ProductSection) => {
      const acc: XAPI[] | undefined = await accP;
      if (hasSectionTypeName(section.type.name)) {
        let questionsArray: Question[] = [];
        const namespace: string = getNameSpace(product.namespace);
        const mInstances: MetadataInstance[] = section.metadata_instances;
        const cDefinition: ContentTemplate | undefined =
          product.component_definitions.find((a: ContentTemplate) => {
            return a.id === section.type.id;
          });

        if (!cDefinition) {
          isValid = false;
          return;
        }

        if (!cDefinition.xapi_type) {
          isValid = false;
        }
        const extensions: Record<string, ExtensionsValue> | null =
          buildExtensions(
            namespace,
            cDefinition.id,
            mInstances,
            cDefinition.metadata_definition
          );
        if (!!extensions) {
          extensions[
            namespace + cnst.xAPI_ContentMetadata + cnst.xAPIComponentName
          ] = cDefinition.name;
        }

        const concepts: string[] = buildExtensionsConcepts(
          section.concepts,
          conceptsArray
        );
        if (concepts.length > 0) {
          if (!!extensions) {
            extensions[cnst.adaptemyNamespace + cnst.xAPIConcept] = concepts;
          }
        }

        // add Questions for QTI from `document`
        if (cnst.isQTIAuthoringToolId(section.type.tool_id)) {
          const questions: QuestionObj[] | undefined = extractQuestions(
            section,
            section.type.tool_id
          );
          if (questions) {
            const extensionsObject: { [k: string]: any } = {};
            if (!!extensions) {
              const keys = Object.keys(extensions);
              keys.map(k => {
                if (extensions) {
                  extensionsObject[k] = extensions[k];
                }
              });
            }

            const builtQuestionsArray: Question[] = buildQuestions(
              questions,
              namespace,
              extensionsObject
            );
            questionsArray = await addHTMLtoQuestions(
              section.id,
              section.document ? section.document : "",
              builtQuestionsArray
            );
          }
        }

        if (cnst.isExternalContentAuthoringToolId(section.type.tool_id)) {
          const externalLink: string | null = getExternalLink(section);

          if (!externalLink || !isAValidURL(externalLink)) {
            isValid = false;
          }
          if (!!extensions) {
            extensions[namespace + cnst.xAPIPathExternalLink] = externalLink;
          }
        }

        if (cnst.isHTMLAuthoringToolId(section.type.tool_id)) {
          const html = getHTML(section);

          if (!html) {
            isValid = false;
          }

          if (!!extensions) {
            extensions[namespace + cnst.xAPI_HTML] = html;
          }
        }

        if (!extensions) {
          isValid = false;
          return acc;
        }
        if (acc) {
          return acc.concat(
            {
              id: namespace + cnst.xAPI_Content + section.id,
              name: section.name,
              definition: {
                type: cDefinition.xapi_type,
                extensions: extensions
              }
            },
            questionsArray
          );
        }
        return acc;
      }
      return acc;
    },
    Promise.resolve([])
  );
  if (isValid) {
    return xAPIJSON;
  } else {
    return undefined;
  }
};

const buildExtensions = (
  namespace: string,
  cDefinitionId: number,
  mInstances: MetadataInstance[],
  mDefinition: MetadataDefinitionItem[]
): Record<string, ExtensionsValue> | null => {
  let isValid = true;

  const extensions = mInstances.reduce(
    (acc: Record<string, ExtensionsValue>, mInst: MetadataInstance) => {
      if (!mInst.metadata_definition.url_name) {
        isValid = false;
      }

      const defForInstance: MetadataDefinitionItem | undefined =
        mDefinition.find((m: MetadataDefinitionItem) => {
          return m.id === mInst.metadata_definition.id;
        });
      if (!defForInstance) {
        isValid = false;
      }
      const key: string = buildExtensionsKey(
        namespace,
        mInst.metadata_definition.url_name
      );
      // find duplicate keys
      if (key in acc) {
        isValid = false;
        return acc;
      }
      if (
        isMdAllocatedValueMoreThenOne(
          mInst.metadata_definition.metadata_allocated_values
        )
      ) {
        acc[key] = mInst.metadata_definition.metadata_allocated_values.reduce(
          (acc2: string[], aValue: MetadataAllocatedValuesItem) => {
            const metadataAllocatedValue: MetadataAllowedValues | undefined =
              defForInstance
                ? defForInstance.metadata_allowed_values.find(
                    (d: MetadataAllowedValues) =>
                      d.id === aValue.metadata_allowed_value_id
                  )
                : undefined;
            const valueName = metadataAllocatedValue
              ? metadataAllocatedValue.display_name
              : "";
            return acc2.concat(valueName);
          },
          []
        );
      } else if (
        isMdAllocatedValueIsEqualToOne(
          mInst.metadata_definition.metadata_allocated_values
        )
      ) {
        const allowedValue = defForInstance
          ? defForInstance.metadata_allowed_values.find(
              (value: MetadataAllowedValues) => {
                return mInst.metadata_definition.metadata_allocated_values.find(
                  (aValue: MetadataAllocatedValuesItem) => {
                    return aValue.metadata_allowed_value_id === value.id;
                  }
                );
              }
            )
          : undefined;
        acc[key] = allowedValue ? allowedValue.display_name : "";
      } else if (
        isMdAllocatedValueAreText(
          mInst.authored_text,
          mInst.metadata_definition.metadata_allocated_values
        )
      ) {
        acc[key] = mInst.authored_text;
      } else {
        isValid = false;
      }
      return acc;
    },
    {}
  );

  if (isValid) {
    return extensions;
  } else {
    return null;
  }
};

const buildExtensionsConcepts = (
  sectionConcepts: SectionConcepts[],
  concepts: string[]
): string[] => {
  if (concepts.length > 0) {
    return sectionConcepts
      .filter(obj => concepts.indexOf(obj.concept_guid) !== -1)
      .map(s => {
        return s.concept_guid;
      });
  } else {
    return [];
  }
};

const buildExternalLink = (
  authoredContent: string,
  toolId: number
): string | null => {
  let link = null;

  const authoredContentJson: ParsedAuthoredContent =
    convertToJsonOrNull(authoredContent);

  if (cnst.isExternalContentAuthoringToolId(toolId) && authoredContentJson) {
    const authTool = cnst.authoringTools.find(
      (t: AuthoringTools) => t.id === toolId
    );
    const authToolKey: string | null = authTool ? authTool.key : null;

    link =
      authToolKey && authoredContentJson[authToolKey]
        ? authoredContentJson[authToolKey]
        : findFirstValue(authoredContentJson.extensions, ([key]) =>
            key.includes(cnst.xAPIPathExternalLink)
          );
  }
  return link;
};

const extractQuestions = (
  section: ProductSection,
  toolId: number
): QuestionObj[] | undefined => {
  const xml = section.document;
  const questions: QuestionObj[] = [];

  if (cnst.isQTIAuthoringToolId(toolId) && xml && xml.length > 0) {
    const item = getAssessmentItem(xml);
    for (let i = 0; i < item.length; i++) {
      const identifier =
        item[i].getElementsByTagName("identifier")[0].childNodes;
      if (identifier.length > 0) {
        const guid = identifier[0].nodeValue;
        const difficultyElement = item[i]
          .getElementsByTagName("LOM")[0]
          .getElementsByTagName("educational")[0]
          .getElementsByTagName("difficulty")[0].childNodes[0];
        const difficulty = difficultyElement ? difficultyElement.nodeValue : "";
        questions.push({
          guid: guid,
          difficulty: difficulty
        });
      }
    }
  }
  return questions.length > 0 ? questions : undefined;
};

const buildQuestions = (
  questions: QuestionObj[],
  namespace: string,
  extensions: {}
): Question[] => {
  return questions.map((question: QuestionObj) => {
    const questionObj = {
      id: namespace + cnst.xAPI_Content + question.guid,
      name: question.name ? question.name : "",
      definition: {
        type: cnst.xAPITypes[1],
        extensions: {
          [namespace + cnst.xAPI_ContentMetadata + cnst.xAPIDifficulty]:
            question.difficulty
        }
      }
    };
    Object.assign(questionObj.definition.extensions, extensions);
    return questionObj;
  });
};

const addHTMLtoQuestions = async (
  sectionId: number,
  qtiDocument: string,
  questions: Question[]
): Promise<Question[]> => {
  const parsedQuestions: QTIQuestionHTML[] = await buildQuestionsHTMLArray(
    qtiDocument,
    []
  );

  return questions.map((question: Question) => {
    const appropriateParsedQuestion: QTIQuestionHTML | undefined =
      parsedQuestions.find(
        (parsedQuestion: QTIQuestionHTML) =>
          parsedQuestion.questionId ===
          question.id.substr(question.id.lastIndexOf("/") + 1)
      );
    if (appropriateParsedQuestion) {
      question.definition.extensions = {
        ...question.definition.extensions,
        [cnst.adaptemyNamespace + cnst.xAPI_Content + cnst.xAPIQuestionHTML]:
          appropriateParsedQuestion.qnHTML,
        [cnst.adaptemyNamespace +
        cnst.xAPI_Content +
        cnst.xAPIQuestionSolutionHTML]: appropriateParsedQuestion.solnHTML,
        [cnst.adaptemyNamespace +
        cnst.xAPI_Content +
        cnst.xAPIQuestionHintHTML]: appropriateParsedQuestion.hintHTML,
        [cnst.adaptemyNamespace +
        cnst.xAPI_Content +
        cnst.xAPIQuestionAnswersHTML]: appropriateParsedQuestion.answers
      };
    }
    return question;
  });
};

const findFirstValue = (
  object: string,
  callback: (entry: [string, string]) => boolean
): string => {
  const firstMatchEntry: [string, string] | undefined =
    Object.entries(object).find(callback);
  if (firstMatchEntry) {
    return firstMatchEntry[1]; // extract value from entry
  }
  return "";
};

const getExternalLinkById = (
  section: ProductSection,
  authoringContent: ExternalContent
): string | null => {
  const currentFile = section.files.find((file: SectionFile): boolean => {
    return (
      file.id ===
      (authoringContent.externalContent as ExternalContentAttachment)
        .attachmentId
    );
  });
  if (currentFile) {
    return JSON.stringify({
      externalContent: currentFile.location
    });
  }
  return null;
};

function getContentErrors(sections: ProductSection[]): XAPIError[] {
  return mergeErrorsArray([
    ...getExternalLinkErrors(sections),
    ...getContentMetadataErrors(sections),
    ...getHTMLErrors(sections)
  ]);
}

function getHTMLErrors(sections: ProductSection[]): XAPIPreError[] {
  return sections.reduce((acc: XAPIPreError[], section: ProductSection) => {
    if (hasSectionTypeName(section.type.name)) {
      if (cnst.isHTMLAuthoringToolId(section.type.tool_id)) {
        const html = getHTML(section);
        if (html) {
          return acc;
        }
        return [
          ...acc,
          { sectionId: section.id, error: cnst.noHTMLDocumentAuthored }
        ];
      }
      return acc;
    }
    return acc;
  }, []);
}

function getHTML(section: ProductSection): string | null {
  return section.document ?? null;
}

function getExternalLinkErrors(sections: ProductSection[]): XAPIPreError[] {
  return sections.reduce((acc: XAPIPreError[], section: ProductSection) => {
    if (hasSectionTypeName(section.type.name)) {
      if (cnst.isExternalContentAuthoringToolId(section.type.tool_id)) {
        const extLink = getExternalLink(section);
        const errorOrNull = validateExternalLink(extLink);
        if (errorOrNull === null) {
          return acc;
        }
        return [...acc, { sectionId: section.id, error: errorOrNull }];
      }
      return acc;
    }
    return acc;
  }, []);
}

function getExternalLink(section: ProductSection): string | null {
  let authContent: string | null;
  const authContentAsAnObject: ExternalContent = JSON.parse(
    section.authored_content
  );
  if (
    !!section.authored_content &&
    authContentAsAnObject.externalContent &&
    authContentAsAnObject.externalContent.hasOwnProperty("attachmentId")
  ) {
    authContent = getExternalLinkById(section, authContentAsAnObject);
  } else {
    authContent = section.authored_content;
  }
  return authContent
    ? buildExternalLink(authContent, section.type.tool_id)
    : null;
}

function validateExternalLink(externalLink: string | null): string | null {
  if (!externalLink || !isAValidURL(externalLink)) {
    return !externalLink
      ? cnst.externalLinkNotSet
      : !isAValidURL(externalLink)
      ? cnst.invalidExternalLink
      : "";
  }
  return null;
}

function getContentTemplatesErrors(product: ProductItem): XAPIError[] {
  return mergeErrorsArray([
    ...getDefinitionXapiTypeErrors(product),
    ...getDefinitionUrlError(product),
    ...getDefinitionDuplicateKeyError(product)
  ]);
}

async function getContentWarnings(sections: any[]): Promise<[] | XAPIError[]> {
  const htmlErrors = await getErrorsInHtmlQuestion(sections);
  return mergeErrorsArray([
    ...htmlErrors,
    ...getErrorsInQuestionQuantity(sections)
  ]);
}

function getContentMetadataErrors(sections: ProductSection[]): XAPIPreError[] {
  return sections.reduce((accErr: XAPIPreError[], section: ProductSection) => {
    if (hasSectionTypeName(section.type.name)) {
      const mdInstanceError = section.metadata_instances.reduce(
        (acc: XAPIPreError[], mInst: MetadataInstance) => {
          if (
            isMdAllocatedValueMoreThenOne(
              mInst.metadata_definition.metadata_allocated_values
            ) ||
            isMdAllocatedValueIsEqualToOne(
              mInst.metadata_definition.metadata_allocated_values
            ) ||
            isMdAllocatedValueAreText(
              mInst.authored_text,
              mInst.metadata_definition.metadata_allocated_values
            )
          ) {
            return acc;
          } else {
            const metadataError = `${cnst.metadataValueMissing}: "${mInst.metadata_definition.name}"`;
            return [...acc, { sectionId: section.id, error: metadataError }];
          }
        },
        []
      );
      return [...accErr, ...mdInstanceError];
    }
    return accErr;
  }, []);
}

function getDefinitionXapiTypeErrors(product: ProductItem): XAPIPreError[] {
  return product.sections.reduce(
    (acc: XAPIPreError[], section: ProductSection) => {
      if (hasSectionTypeName(section.type.name)) {
        const cDefinition = product.component_definitions.find(
          a => a.id === section.type.id
        );

        // should we throw an error?
        if (!cDefinition) {
          return acc;
        }
        if (!cDefinition.xapi_type) {
          const typeError = `${cnst.xApiTypeNotSet}: "${cDefinition.name}"`;
          return [...acc, { sectionId: cDefinition.id, error: typeError }];
        }
        return acc;
      }
      return acc;
    },
    []
  );
}

function getDefinitionUrlError(product: ProductItem): XAPIPreError[] {
  return product.sections.reduce(
    (accErr: XAPIPreError[], section: ProductSection) => {
      if (hasSectionTypeName(section.type.name)) {
        const mInstances = section.metadata_instances;
        const cDefinition = product.component_definitions.find(
          a => a.id === section.type.id
        );
        let mInstancesErrors;
        if (cDefinition) {
          mInstancesErrors = mInstances.reduce(
            (acc: XAPIPreError[], mInst: MetadataInstance) => {
              if (!mInst.metadata_definition.url_name) {
                const URLError = `${cnst.metadataFriendlyURlNotSet}: "${mInst.metadata_definition.name}"`;
                return [...acc, { sectionId: cDefinition.id, error: URLError }];
              }
              return acc;
            },
            []
          );
        } else {
          // should we throw an error?
          return accErr;
        }
        return [...accErr, ...mInstancesErrors];
      }
      return accErr;
    },
    []
  );
}

function getDefinitionDuplicateKeyError(product: ProductItem): XAPIPreError[] {
  const namespace = getNameSpace(product.namespace);
  return product.sections.reduce(
    (accErr: XAPIPreError[], section: ProductSection) => {
      if (hasSectionTypeName(section.type.name)) {
        const mInstances = section.metadata_instances;
        const cDefinition = product.component_definitions.find(
          (a: ContentTemplate) => a.id === section.type.id
        );
        const instanceKeys: string[] = [];
        const mInstancesKeyErrors = mInstances.reduce(
          (acc: XAPIPreError[], mInst: MetadataInstance) => {
            const key: string =
              buildExtensionsKey(
                namespace,
                mInst.metadata_definition.url_name
              ) || "";

            // find duplicate keys
            if (hasKeyInArray(key, instanceKeys) && cDefinition) {
              const duplicateError = `${cnst.duplicateMetadataFriendlyUrl}: "${mInst.metadata_definition.name}"`;
              return [
                ...acc,
                { sectionId: cDefinition.id, error: duplicateError }
              ];
            }
            instanceKeys.push(key);
            return acc;
          },
          []
        );
        return [...accErr, ...mInstancesKeyErrors];
      }
      return accErr;
    },
    []
  );
}

function mergeErrorsArray(errorsArray: XAPIPreError[]): XAPIError[] {
  const errors: XAPIError[] = [];
  for (let i = 0; i < errorsArray.length; i++) {
    const isItemInErrorsArray = errors.some(
      (item: XAPIError): boolean => item.sectionId === errorsArray[i].sectionId
    );
    if (!isItemInErrorsArray) {
      errors.push({
        sectionId: errorsArray[i].sectionId,
        errors: [errorsArray[i].error]
      });
    } else {
      const currentItemInErrorsArray = errors.find(
        (item: XAPIError): boolean =>
          item.sectionId === errorsArray[i].sectionId
      );
      if (currentItemInErrorsArray) {
        if (!currentItemInErrorsArray.errors.includes(errorsArray[i].error)) {
          currentItemInErrorsArray.errors.push(errorsArray[i].error);
        }
      }
    }
  }
  return errors;
}

async function getErrorsInHtmlQuestion(
  sections: ProductSection[]
): Promise<XAPIPreError[]> {
  return await sections.reduce(
    async (accP: Promise<XAPIPreError[]>, section: ProductSection) => {
      const acc = await accP;
      if (cnst.isQTIAuthoringToolId(section.type.tool_id)) {
        let parsedQuestions;
        if (section.document) {
          const doc = section.document ? section.document : "";
          parsedQuestions = await buildQuestionsHTMLArray(doc, []);
        }
        if (parsedQuestions) {
          if (hasErrorsInParsedQuestions(parsedQuestions)) {
            return [
              ...acc,
              { sectionId: section.id, error: cnst.questionsContainErrors }
            ];
          }
        }
      }
      return acc;
    },
    Promise.resolve([])
  );
}

function hasErrorsInParsedQuestions(
  parsedQuestions: QTIQuestionHTML[]
): boolean {
  return parsedQuestions.some((question: QTIQuestionHTML) => {
    return question.errors.length > 0;
  });
}

function getErrorsInQuestionQuantity(
  sections: ProductSection[]
): XAPIPreError[] {
  return sections.reduce((acc: XAPIPreError[], section: ProductSection) => {
    const xml = section.document ? section.document : "";
    if (
      cnst.isQTIAuthoringToolId(section.type.tool_id) &&
      xml &&
      xml.length > 0
    ) {
      if (isItemLengthZero(getAssessmentItem(xml))) {
        return [
          ...acc,
          { sectionId: section.id, error: cnst.noQuestionsAuthored }
        ];
      }
    }
    return acc;
  }, []);
}

function getAssessmentItem(xml: string): HTMLCollectionOf<Element> {
  const parser = new DOMParser();
  const cleanXML = xml.replace(/<\?fonto.*?\?>/g, "");
  const xmlDoc = parser.parseFromString(cleanXML, "text/xml");
  return xmlDoc.getElementsByTagName("assessmentItem");
}

function isMdAllocatedValueMoreThenOne(
  allocatedValues: MetadataAllocatedValuesItem[]
): boolean {
  return allocatedValues.length > 1;
}

function isMdAllocatedValueIsEqualToOne(
  allocatedValues: MetadataAllocatedValuesItem[]
): boolean {
  return allocatedValues.length === 1;
}

function isMdAllocatedValueAreText(
  text: string,
  allocatedValues: MetadataAllocatedValuesItem[]
): boolean {
  return !!text && allocatedValues.length === 0;
}

function getNameSpace(namespace: string): string {
  return namespace.replace(/\/$/, "");
}

function hasKeyInArray(key: string, arrayToTest: string[]): boolean {
  return arrayToTest.includes(key);
}

function buildExtensionsKey(namespace: string, urlName: string): string {
  return namespace + cnst.xAPI_ContentMetadata + urlName;
}

function hasSectionTypeName(name: string): boolean {
  return !cnst.sectionsToSkip.includes(name);
}

function isItemLengthZero(item: HTMLCollectionOf<Element>): boolean {
  return item.length === 0;
}

export {
  hasNameSpace,
  buildXAPI,
  buildExtensions,
  buildExtensionsConcepts,
  buildExternalLink,
  extractQuestions,
  buildQuestions,
  addHTMLtoQuestions,
  validateBuildXAPI,
  getContentErrors,
  mergeErrorsArray,
  getContentTemplatesErrors,
  getContentWarnings
};
