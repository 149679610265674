<template>
  <div>
    <b-collapse
      class="card"
      animation="slide"
      aria-id="contentIdForA11y3"
      :model-value="workflowIsOpen"
      @open="toggleWorkflow"
      @close="toggleWorkflow"
      data-testing="workflow-collapse"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
        >
          <p class="card-header-title title-flex pr-0">
            <span>Tasks in {{ section.name }}</span>
            <span v-if="percentageComplete > 0" class="percent-complete"
              >{{ percentageComplete }}% Complete</span
            >
          </p>
          <a class="card-header-icon">
            <SVGIcon :path="props.open ? mdiMenuDown : mdiMenuUp" :size="24" />
          </a>
        </div>
      </template>

      <div class="card-content outer-container">
        <div class="content">
          <div>
            <content-workflow-item
              v-for="transition in allowedTransitions"
              :key="transition.id"
              :transition="transition"
              :currentState="currentState"
              :isPinnedState="true"
              :submittingStateTransition="submittingStateTransition"
              @requestTransitionConfirmation="requestTransitionConfirmation"
            />
          </div>
        </div>
      </div>
    </b-collapse>
    <content-workflow-item
      v-if="!workflowIsOpen && pinnedState"
      :transition="pinnedState"
      :currentState="currentState"
      :isPinnedState="true"
      :submittingStateTransition="submittingStateTransition"
      @requestTransitionConfirmation="requestTransitionConfirmation"
    />
    <b-modal
      :model-value="isConfirmTransitionOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
    >
      <div v-if="pendingTransition" class="modal-card white-bg">
        <h3 class="padding-standard border-bottom">
          Move
          <em>{{ section.name }}</em> to
          <em>{{ pendingTransition.name }}</em>
        </h3>
        <form
          class="padding-standard"
          @submit.prevent="confirmTransition(pendingTransition.id)"
        >
          <label class="margin-b-half">
            Enter a message <small>(optional)</small>
            <textarea v-model="transitionMessage"></textarea>
          </label>

          <button
            data-testing="confirm-transition"
            type="submit"
            class="btn-1 float-right"
          >
            Confirm
          </button>
          <button
            @click.prevent="closeTransitionConfirmation"
            class="btn-2 float-right margin-r-standard"
          >
            Cancel
          </button>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  PendingTransition,
  SidebarTransitionState
} from "@/models/transition-state.model";
import { OrganizationUser } from "@/models/user.model";
import { WorkflowState } from "@/models/workflow.model";
import FeatureFlagsMixin from "@/mixins/feature-flags";
import { formatCourse } from "@/utils/formatTimestamp";
import { TaskStatus } from "@/models/task.model";
import ContentWorkflowService from "@/utils/product/content-workflow.service";
import ContentWorkflowItem from "./ContentWorkflowItem.vue";
import cnst from "../../../utils/constants";
import { ProductItem } from "@/models/product.model";
import UserInterfaceFeatureFlaggerMixin from "@/mixins/user-interface-feature-flagger";
import { ContentStructureSection } from "@/models/content-structure.model";
import {
  getDeadlineByWorkflowState,
  getTaskStatus
} from "@/utils/tasks-helpers";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { mapStores } from "pinia";
import { useUsersStore } from "@/stores/users.store";
import { useProductsStore } from "@/stores/products.store";
import { useContentStore } from "@/stores/content.store";

interface Data {
  submittingStateTransition: boolean;
  isConfirmTransitionOpen: boolean;
  pendingTransition: any;
  transitionMessage: string;
  workflowIsOpen: boolean;
  mdiMenuDown: string;
  mdiMenuUp: string;
}

export default defineComponent({
  name: "ContentWorkflow",
  mixins: [FeatureFlagsMixin, UserInterfaceFeatureFlaggerMixin],
  components: {
    ContentWorkflowItem
  },
  props: {
    section: {
      required: true,
      type: Object as PropType<ContentStructureSection>
    },
    sectionId: {
      required: true,
      type: Number
    },
    productId: {
      required: true,
      type: Number
    },
    workflowStates: {
      required: true,
      type: Array as PropType<WorkflowState[]>
    },
    currentState: {
      required: true,
      type: Object as PropType<WorkflowState>
    },
    workflowStateFromTitleId: {
      required: true,
      type: Number
    }
  },
  data(): Data {
    return {
      submittingStateTransition: false,
      isConfirmTransitionOpen: false,
      pendingTransition: undefined,
      transitionMessage: "",
      workflowIsOpen: false,
      mdiMenuDown,
      mdiMenuUp
    };
  },
  computed: {
    ...mapStores(useUsersStore, useProductsStore, useContentStore),
    product(): ProductItem | undefined {
      return this.productsStore.productDetails?.find(
        (e: ProductItem): boolean => e.id === Number(this.productId)
      );
    },
    allowedTransitions(): SidebarTransitionState[] {
      return this.workflowStates
        .filter((wfState: WorkflowState): boolean => !wfState.isFinalState)
        .map((wfState: WorkflowState): SidebarTransitionState => {
          const { id, name, rank, instruction } = wfState;
          const status: TaskStatus | undefined = this.product
            ? getTaskStatus(this.section, wfState, this.product)
            : undefined;
          if (id && name && status && rank) {
            let stateDetails: SidebarTransitionState = {
              id,
              name,
              rank,
              status,
              instruction,
              isTransitionAvailableForThisUser: false,
              assignedUsers: this.getUsersAssignedToOtherWorkflow(
                wfState.users
              ),
              deadline: this.getDeadlineForOtherWorkflow(wfState),
              availableTransitions:
                this.getAllAvailableTransitionsFromThisStatus(
                  {
                    id,
                    name,
                    status,
                    rank
                  },
                  wfState.users
                )
            };
            stateDetails.isTransitionAvailableForThisUser =
              this.isTransitionAvailableForThisUser(stateDetails);
            return stateDetails;
          }
          return {
            assignedUsers: [],
            availableTransitions: [],
            deadline: undefined,
            id: undefined,
            instruction: "",
            isTransitionAvailableForThisUser: false,
            name: undefined,
            rank: undefined,
            status: TaskStatus.COMPLETE
          };
        });
    },
    percentageComplete(): number {
      const numComplete: number = this.allowedTransitions.filter(
        (wf: SidebarTransitionState) => {
          return wf.status === TaskStatus.COMPLETE;
        }
      ).length;
      const numTasksWhichAreActionable: number = this.allowedTransitions.filter(
        (wf: SidebarTransitionState) => {
          return wf.status !== TaskStatus.FINAL_STATE;
        }
      ).length;
      return Math.round((numComplete * 100) / numTasksWhichAreActionable);
    },
    pinnedState(): SidebarTransitionState | undefined {
      let stateInProgress = this.allowedTransitions.find(
        (transition: SidebarTransitionState): boolean =>
          transition.id === this.currentState.id
      );
      if (this.workflowStateFromTitleId) {
        return this.allowedTransitions.find(
          (transition: SidebarTransitionState): boolean =>
            transition.id === this.workflowStateFromTitleId
        );
      }
      return stateInProgress;
    },
    isWorkflowManagementUserFeatureEnabled(): boolean {
      return this.product
        ? this.isWorkflowManagementFeatureEnabled(this.product.id)
        : false;
    }
  },
  methods: {
    toggleWorkflow(): void {
      this.workflowIsOpen = !this.workflowIsOpen;
    },
    getAllAvailableTransitionsFromThisStatus(
      stateDetails: {
        id: number;
        name: string;
        status: TaskStatus;
        rank: number;
      },
      assignedUsers: number[]
    ): PendingTransition[] {
      // Tasks which are upcoming/complete can be moved to in progress
      // Tasks which are upcoming/inprogress can be moved to complete
      let nextAvailableStatuses: PendingTransition[] = [];

      if (
        stateDetails.status === TaskStatus.UPCOMING ||
        stateDetails.status === TaskStatus.UPCOMING_LATE ||
        stateDetails.status === TaskStatus.FINAL_STATE ||
        stateDetails.status === TaskStatus.COMPLETE
      ) {
        nextAvailableStatuses.push({
          name: stateDetails.name,
          status: TaskStatus.CURRENT,
          availableStatus: "Mark as In Progress",
          id: stateDetails.id,
          assignedUsers: assignedUsers,
          currentStatus: stateDetails.status,
          taskName: stateDetails.name
        });
      }

      if (
        stateDetails.status === TaskStatus.UPCOMING ||
        stateDetails.status === TaskStatus.UPCOMING_LATE ||
        stateDetails.status === TaskStatus.CURRENT ||
        stateDetails.status === TaskStatus.CURRENT_LATE
      ) {
        const nextRankedTask: WorkflowState | undefined =
          this.workflowStates.find(
            (wf: WorkflowState): boolean => wf.rank === stateDetails.rank + 1
          );

        nextAvailableStatuses.push({
          name: nextRankedTask ? nextRankedTask.name : "",
          status: TaskStatus.COMPLETE,
          availableStatus: "Mark as Complete",
          id: nextRankedTask ? nextRankedTask.id : undefined,
          assignedUsers: assignedUsers,
          currentStatus: stateDetails.status,
          taskName: stateDetails.name
        });
      }

      // tasks which are currently active can be moved to the previous state
      if (
        stateDetails.status === TaskStatus.CURRENT ||
        stateDetails.status === TaskStatus.CURRENT_LATE
      ) {
        if (stateDetails.rank !== this.workflowStates[0].rank) {
          const prevRankedTask: WorkflowState | undefined =
            this.workflowStates.find(
              (wf: WorkflowState): boolean => wf.rank === stateDetails.rank - 1
            );

          nextAvailableStatuses.push({
            name: prevRankedTask ? prevRankedTask.name : "",
            status: TaskStatus.UPCOMING,
            availableStatus: "Move to previous state",
            id: prevRankedTask ? prevRankedTask.id : undefined,
            assignedUsers: assignedUsers,
            currentStatus: stateDetails.status,
            taskName: stateDetails.name
          });
        }
      }

      return nextAvailableStatuses;
    },
    requestTransitionConfirmation(transition: PendingTransition): void {
      this.pendingTransition = transition;
      this.isConfirmTransitionOpen = true;
    },
    closeTransitionConfirmation(): void {
      this.isConfirmTransitionOpen = false;
      this.transitionMessage = "";
      this.pendingTransition = undefined;
    },
    confirmTransition(): Promise<void> {
      let promises = [];
      promises.push(this.submitTransition(this.pendingTransition.id));
      if (this.transitionMessage) {
        promises.push(
          this.contentStore.submitMessage({
            productId: this.productId,
            sectionId: this.sectionId,
            message: this.transitionMessage
          })
        );
      }
      return Promise.all(promises).then((): void => {
        this.pendingTransition = undefined;
        this.transitionMessage = "";
        this.isConfirmTransitionOpen = false;
      });
    },
    submitTransition(transitionId: number): Promise<void> {
      this.submittingStateTransition = true;
      return this.contentStore
        .submitStateTransition({
          shouldProductBeUpdated: true,
          productId: this.productId,
          stateId: transitionId,
          sectionId: this.sectionId,
          users_to_notify:
            this.ffShouldTaskNotificationsBeSent &&
            this.isWorkflowManagementUserFeatureEnabled
              ? this.pendingTransition.assignedUsers
              : [],
          status: {
            statusBefore: (
              cnst.taskStatusesDisplayName as Record<string, string>
            )[this.pendingTransition.currentStatus],
            statusAfter: (
              cnst.taskStatusesDisplayName as Record<string, string>
            )[this.pendingTransition.status],
            taskName: this.pendingTransition.taskName
          }
        })
        .then((): void => {
          this.submittingStateTransition = false;
        });
    },
    getDeadlineForOtherWorkflow(wfState: WorkflowState): string | undefined {
      const taskDeadline: number | undefined = this.product
        ? getDeadlineByWorkflowState(this.section, wfState, this.product)
        : undefined;

      return taskDeadline ? this.formatTimestampCourse(taskDeadline) : "";
    },
    formatTimestampCourse(date: number): string | undefined {
      return date ? formatCourse(date) : undefined;
    },
    getUsersAssignedToOtherWorkflow(
      workflowUsers: number[]
    ): OrganizationUser[] {
      return this.usersStore.users
        ? this.usersStore.users.filter((userToCheck: OrganizationUser) => {
            return workflowUsers.some((assignedUserId: number) => {
              return assignedUserId === userToCheck.id;
            });
          })
        : [];
    },
    isTransitionAvailableForThisUser(
      transition: SidebarTransitionState
    ): boolean {
      return (
        !!this.usersStore.currentUser &&
        ContentWorkflowService.isTransitionAvailableForThisUser(
          transition,
          this.workflowStates,
          this.currentState,
          this.usersStore.currentUser.id,
          this.usersStore.canUserManageWorkflows
        )
      );
    }
  }
});
</script>
<style lang="scss" scoped>
.card-header {
  box-shadow: none;
  &-title {
    padding: 0.75rem;
    font-weight: 700;
  }
}
.title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    max-width: 100px;
  }
}
.card-content.outer-container {
  padding: 0;
}
.card-content {
  padding: 0 12px 12px; // to match title
}
.content ul {
  margin: 5px;
  padding: 0 1rem 0;
}

.btn-transition {
  font-size: 14px;
  font-weight: normal;
  border: 0;
}
.btn-upcoming {
  background: $border-color-dark;
}
.btn-in-progress {
  background: $warning-color-transparent;
}
.btn-complete {
  background: $success-color;
}
.white-bg {
  background-color: white;
}
.percent-complete {
  color: $primary-color-light;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
}
</style>
