<template>
  <Collapse :class="{ updating: isUpdating }">
    <template v-slot:title>
      Metadata
      <Hint v-if="XAPIErrors.length > 0" :type="'error'" :position="'top'">
        <ul>
          <li v-for="(error, index) in XAPIErrors" :key="index">{{ error }}</li>
        </ul>
      </Hint>
    </template>
    <template v-if="canEditContent" v-slot:content>
      <div v-for="(item, index) in section.metadata_instances" :key="item.id">
        <div v-if="item.metadata_definition.type === 'Text Free'">
          <strong class="margin-r-half"
            >{{ item.metadata_definition.name }}:</strong
          >
          <input
            type="text"
            :value="item.authored_text"
            @change="updateMetadataText(item.id, $event)"
          />
        </div>
        <div v-else>
          <!-- if type is 'Single option' or 'Multiple options' -->
          <strong class="margin-r-half"
            >{{ item.metadata_definition.name }}
            <small
              >(select
              {{
                item.metadata_definition.type === "Single Option"
                  ? "one"
                  : "multiple"
              }})
            </small>
          </strong>
          <ul class="list-unstyled">
            <li
              v-for="value in getMetadataAllowedValues(
                item.metadata_definition.id
              )"
              :key="value.id"
            >
              <label :for="value.id">
                <input
                  v-if="item.metadata_definition.type === 'Single Option'"
                  :id="value.id"
                  type="radio"
                  :name="item.metadata_definition.name"
                  :value="value.display_name"
                  :checked="isMetadataValueChecked(item.id, value.id)"
                  @change="toggleMetadataAllocatedValue(item.id, $event)"
                />
                <input
                  v-if="item.metadata_definition.type === 'Multiple Options'"
                  :id="value.id"
                  type="checkbox"
                  :name="item.metadata_definition.name"
                  :checked="isMetadataValueChecked(item.id, value.id)"
                  @change="toggleMetadataAllocatedValue(item.id, $event)"
                />
                {{ value.display_name }}
              </label>
            </li>
          </ul>
        </div>
        <hr v-if="index < section.metadata_instances.length - 1" />
      </div>
    </template>
    <template v-else v-slot:content>
      <div v-for="(item, index) in displayedMetadata" :key="item.id">
        <div v-if="item.metadata_definition.type === 'Text Free'">
          <strong class="margin-r-half"
            >{{ item.metadata_definition.name }}:</strong
          >
          <span>{{ item.authored_text }}</span>
        </div>
        <div v-else>
          <!-- if type is 'Single option' or 'Multiple options' -->
          <strong class="margin-r-half">{{
            item.metadata_definition.name
          }}</strong>
          <ul class="list-disk">
            <li
              v-for="value in getMetadataCheckedValues(item.id)"
              :key="value.id"
            >
              <span>{{ value.name }}</span>
            </li>
          </ul>
        </div>
        <hr v-if="index < displayedMetadata.length - 1" />
      </div>
    </template>
  </Collapse>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import Collapse from "../ui/Collapse.vue";
import { ProductItem } from "@/models/product.model";
import {
  ContentTemplate,
  MetadataDefinitionItem,
  MetadataAllowedValues
} from "@/models/content-templates.model";
import Hint from "@/components/Hint.vue";
import {
  MetadataAllocatedValuesItem,
  MetadataInstance
} from "@/models/product-section.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mapStores } from "pinia";
import { useProductsStore } from "@/stores/products.store";
import { useMetadataStore } from "@/stores/metadata.store";

interface MetadataCheckedValue {
  id: number;
  name: string;
}

export default defineComponent({
  name: "ContentMetadata",
  props: {
    isUpdating: {
      required: false,
      type: Boolean
    },
    section: {
      required: true,
      type: Object as PropType<ContentStructureSection>
    },
    productId: {
      required: true,
      type: Number
    },
    canEditContent: {
      required: true,
      type: Boolean
    },
    displayedMetadata: {
      required: true,
      type: Array as PropType<MetadataInstance[]>
    },
    XAPIErrors: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => []
    }
  },
  components: { Collapse, Hint },
  computed: {
    ...mapStores(useProductsStore, useMetadataStore),
    product(): ProductItem | undefined {
      if (
        this.productsStore.areProductDetailsLoading ||
        !this.productsStore.productDetails
      ) {
        return undefined;
      }
      return this.productsStore.productDetails.find(
        (e: ProductItem): boolean => e.id === Number(this.productId)
      );
    },
    template(): ContentTemplate | undefined {
      if (!this.product) {
        return undefined;
      }
      return this.product.component_definitions.find(
        (e: ContentTemplate): boolean => e.id === this.section.type.id
      );
    }
  },
  methods: {
    async updateMetadataText(
      instanceId: number,
      event: { currentTarget: HTMLInputElement }
    ): Promise<void> {
      const payload = {
        metadataInstance: {
          authored_text: event.currentTarget.value,
          id: instanceId
        },
        productId: this.productId
      };
      await this.metadataStore.updateMetadataInstance(payload);
    },
    getMetadataInstance(instanceId: number): MetadataInstance | undefined {
      return this.section.metadata_instances.find(
        (e: MetadataInstance): boolean => e.id === instanceId
      );
    },
    isMetadataValueChecked(
      instanceId: number,
      allowedValueId: number
    ): boolean {
      const instance: MetadataInstance | undefined =
        this.getMetadataInstance(instanceId);
      if (instance) {
        return instance.metadata_definition.metadata_allocated_values.some(
          (e: MetadataAllocatedValuesItem): boolean =>
            e.metadata_allowed_value_id === allowedValueId
        );
      }
      return false;
    },
    getMetadataAllowedValues(id: number): MetadataAllowedValues[] {
      if (this.template) {
        const metadataDefinition: MetadataDefinitionItem | undefined =
          this.template.metadata_definition.find(
            (e: MetadataDefinitionItem): boolean => e.id === id
          );
        return metadataDefinition
          ? metadataDefinition.metadata_allowed_values
          : [];
      }
      return [];
    },
    getMetadataSingleAllocatedValueId(instanceId: number): number | undefined {
      const instance: MetadataInstance | undefined =
        this.getMetadataInstance(instanceId);
      if (
        instance &&
        instance.metadata_definition.metadata_allocated_values.length > 0
      ) {
        return instance.metadata_definition.metadata_allocated_values[0].id;
      }
      return undefined;
    },
    getMetadataMultipleAllocatedValueId(
      instanceId: number,
      allowedValueId: number
    ): number | undefined {
      const instance: MetadataInstance | undefined =
        this.getMetadataInstance(instanceId);
      if (
        instance &&
        instance.metadata_definition.metadata_allocated_values.length > 0
      ) {
        const currentValue =
          instance.metadata_definition.metadata_allocated_values.find(
            (e: MetadataAllocatedValuesItem): boolean =>
              e.metadata_allowed_value_id === Number(allowedValueId)
          );
        return currentValue ? currentValue.id : undefined;
      }
      return undefined;
    },
    async toggleMetadataAllocatedValue(
      instanceId: number,
      event: { currentTarget: HTMLInputElement }
    ): Promise<void> {
      if (!event.currentTarget.checked) {
        const id = this.getMetadataMultipleAllocatedValueId(
          instanceId,
          Number(event.currentTarget.id)
        );
        if (id) {
          const payload = {
            id,
            productId: this.productId
          };
          await this.metadataStore.deleteMetadataAllocatedValues(payload);
        }
      } else {
        const id = this.getMetadataSingleAllocatedValueId(instanceId);
        if (
          id &&
          event.currentTarget.type === "radio" &&
          this.getMetadataSingleAllocatedValueId(instanceId)
        ) {
          const payload = {
            id,
            productId: this.productId
          };
          await this.metadataStore.deleteMetadataAllocatedValues(payload);
        }

        const payload = {
          value: {
            metadata_allowed_value_id: Number(event.currentTarget.id),
            metadata_instance_id: instanceId
          },
          productId: this.productId
        };
        await this.metadataStore.createMetadataAllocatedValues(payload);
      }
    },
    getMetadataCheckedValues(instanceId: number): MetadataCheckedValue[] {
      const instance: MetadataInstance | undefined =
        this.getMetadataInstance(instanceId);
      if (instance) {
        const allowedValues: MetadataAllowedValues[] =
          this.getMetadataAllowedValues(instance.metadata_definition.id);
        return allowedValues
          .filter((value: MetadataAllowedValues): boolean =>
            this.isMetadataValueChecked(instanceId, value.id)
          )
          .map((value: MetadataAllowedValues): MetadataCheckedValue => {
            return {
              id: value.id,
              name: value.display_name
            };
          });
      }
      return [];
    }
  }
});
</script>
<style lang="scss" scoped>
hr {
  width: 100%;
  border-bottom: 1px solid $border-color;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
