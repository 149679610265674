<template>
  <div class="modal-card">
    <div class="modal-card-header">
      <h3>Upload a product image</h3>
    </div>
    <form
      id="product-image-form"
      class="modal-card-content"
      @submit.prevent="handleFileUpload"
    >
      <label class="margin-b-standard">
        <input
          type="file"
          id="product-image-upload-input"
          ref="product-image-upload-input"
          @change="fetchLocalFile"
        />
      </label>
      <p class="warning" v-if="fileTypeError">{{ fileTypeError }}</p>
    </form>
    <div class="modal-card-footer">
      <button
        form="product-image-form"
        type="submit"
        :disabled="!fileToUpload"
        class="btn-1 full-width submit-btn"
      >
        Set as Product Image
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import cnst from "@/utils/constants";
import fileHelpers from "@/utils/file-helpers";
import { mapStores } from "pinia";
import { useUIProductsStore } from "@/stores/ui-products.store";
import { useProductsStore } from "@/stores/products.store";

interface Data {
  fileToUpload: any;
  fileTypeError: string;
}

export default defineComponent({
  name: "AddProductImage",
  data(): Data {
    return {
      fileToUpload: undefined,
      fileTypeError: ""
    };
  },
  computed: {
    ...mapStores(useUIProductsStore, useProductsStore)
  },
  methods: {
    fetchLocalFile(): void {
      const fileInput = this.$refs[
        "product-image-upload-input"
      ] as HTMLInputElement;
      if (fileInput.files) {
        const fileType = fileInput.files[0].type;
        if (!fileHelpers.isValidImageFileType(fileType)) {
          this.fileTypeError = cnst.imageFileTypeError;
          return;
        }
        this.fileTypeError = "";
        this.fileToUpload = fileInput.files[0];
      }
    },
    handleFileUpload(): void {
      if (
        this.uiProductsStore.productToAddImage &&
        Number(this.uiProductsStore.productToAddImage) > 0
      ) {
        // if we change an image in an existing product
        const formData = new FormData();
        formData.append("image", this.fileToUpload);
        formData.append(
          "product_id",
          `${this.uiProductsStore.productToAddImage}`
        );
        const payload = {
          formData: formData,
          product_id: this.uiProductsStore.productToAddImage
        };
        this.productsStore.addProductImage(payload).then(() => {
          this.uiProductsStore.closeProductImageModal();
        });
      } else {
        // if a product is new
        this.uiProductsStore.setNewProductImage(this.fileToUpload);
        this.uiProductsStore.closeProductImageModal();
      }
    }
  }
});
</script>
<style scoped lang="scss">
.modal-card {
  width: auto;
  min-width: 31rem;
}
</style>
