<template>
  <div id="app" data-testing="main-container">
    <component :is="layout" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PopulateStoreMixin from "@/mixins/populate-store";
import ls from "@/services/local-storage";
import API from "@/api/index";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import { TrackJS } from "trackjs";
import { mapStores } from "pinia";
import { useUsersStore } from "@/stores/users.store";
import { useAuthStore } from "@/stores/auth.store";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIFilesStore } from "@/stores/ui-files.store";

export default defineComponent({
  components: {
    AuthLayout,
    MainLayout
  },
  mixins: [PopulateStoreMixin],
  async mounted() {
    TrackJS.install({
      token: "b784bd4d4eac4f46a055b4b1771f56a7",
      application: "thoth"
    });
    if (this.$router.currentRoute) {
      if (this.isAuthPage) {
        return;
      }
      if (ls.isTokenSet()) {
        this.authStore.setLoggedIn();
        const isValid = await this.validateAPIToken();
        if (isValid) {
          await this.populateOrganizationData();
          this.populateStoreWithGeneralData();
          if (this.usersStore.currentUser) {
            TrackJS.configure({
              userId: `${this.usersStore.currentUser.id}`
            });
            TrackJS.addMetadata("logged-in-via", "browser");
          }
        } else {
          ls.clearToken();
          this.authStore.clearLoggedIn();
          await this.$router.push({ name: "login" });
        }
      }
    }
  },
  computed: {
    ...mapStores(
      useUsersStore,
      useAuthStore,
      useUIGeneralStore,
      useUIFilesStore
    ),
    layout(): string {
      if (this.$route.meta) {
        return (this.$route.meta.layout || "main") + "-layout";
      }
      return "main-layout";
    },
    isAuthPage(): boolean {
      return this.$route.name === "authStart" || this.$route.name === "authEnd";
    }
  },
  methods: {
    validateAPIToken(): Promise<boolean> {
      return API.product.getAll().then(
        function () {
          return true;
        },
        function (error) {
          return error.response.status !== 401;
        }
      );
    }
  },
});
</script>

<style lang="scss"></style>
