<template>
  <div
    :class="[
      'main-layout',
      { 'right-sidebar-padding': uiGeneralStore.isRightSidebarOpen }
    ]"
  >
    <b-modal
      :model-value="uiFilesStore.isAttachFileModalOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiFilesStore.closeAttachFileModal"
      class="full-screen-modal"
    >
      <AttachFile></AttachFile>
    </b-modal>

    <b-modal
      :model-value="uiContentTemplatesStore.isAddMetadataModalOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiContentTemplatesStore.closeAddMetadataModal"
      class="full-screen-modal"
    >
      <AddMetadata></AddMetadata>
    </b-modal>

    <b-modal
      :model-value="uiProductsStore.isProductImageModalVisible"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiProductsStore.closeProductImageModal"
      class="full-screen-modal"
    >
      <AddProductImage></AddProductImage>
    </b-modal>

    <Sidebar />
    <ContentSidebar />
    <FileSidebar />
    <CreateSection />
    <div>
      <ToggleEditMode />
      <Header />
      <div class="main-view">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { defineComponent } from "vue";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import Header from "@/components/header/Header.vue";
import ContentSidebar from "@/components/right-sidebars/content-sidebar/ContentSidebar.vue";
import CreateSection from "@/components/right-sidebars/CreateSection.vue";
import FileSidebar from "@/components/right-sidebars/FileSB.vue";
import AttachFile from "@/components/popups/attachments/attach-file/AttachFile.vue";
import AddMetadata from "@/components/popups/AddMetadata.vue";
import AddProductImage from "@/components/popups/AddProductImage.vue";
import ToggleEditMode from "@/components/header/ToggleEditMode.vue";
import { mapStores } from "pinia";
import { useUIProductsStore } from "@/stores/ui-products.store";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useUIContentTemplatesStore } from "@/stores/ui-content-template.store";
import { useUIGeneralStore } from "@/stores/ui-general.store";

export default defineComponent({
  name: "MainLayout",
  components: {
    Sidebar,
    Header,
    ContentSidebar,
    FileSidebar,
    AttachFile,
    AddMetadata,
    AddProductImage,
    ToggleEditMode,
    CreateSection
  },
  computed: {
    ...mapStores(
      useUIProductsStore,
      useUIFilesStore,
      useUIContentTemplatesStore,
      useUIGeneralStore
    )
  }
});
</script>
<style lang="scss" scoped>
.main-layout {
  transition: padding-right 0.3s ease-out;
}
#app .full-screen-modal,
.dialog.modal.is-active {
  z-index: 902; // Must be greater than the z-index of the sidebar
}
</style>
