import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2fd95e5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "margin-b-standard",
  "data-testing": "mcq-card"
}
const _hoisted_2 = { class: "tools-buttons" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "btn-3 disabled margin-r-standard",
  "data-testing": "qti-card-btn-preview"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RightSidebarTitle = _resolveComponent("RightSidebarTitle")!
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RightSidebarTitle, { title: 'MCQ Mode' }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        disabled: _ctx.isPreviewing || !_ctx.isContentAuthoringTier2FeatureEnabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPreview && _ctx.goToPreview(...args))),
        class: "btn-3",
        type: "button",
        "data-testing": "mcq-card-btn-preview"
      }, [
        _createVNode(_component_SVGIcon, { path: _ctx.mdiPresentationPlay }, null, 8, ["path"]),
        _createTextVNode(" Preview ")
      ], 8, _hoisted_3),
      _createElementVNode("button", {
        disabled: _ctx.isDownloading || !_ctx.isContentAuthoringTier2FeatureEnabled,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.triggerDownload && _ctx.triggerDownload(...args))),
        class: "btn-3",
        type: "button",
        "data-testing": "mcq-card-btn-download"
      }, [
        _createVNode(_component_SVGIcon, { path: _ctx.mdiDownload }, null, 8, ["path"]),
        _createTextVNode(" Download ")
      ], 8, _hoisted_4),
      (!_ctx.isContentAuthoringTier2FeatureEnabled)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createVNode(_component_SVGIcon, { path: _ctx.mdiPencil }, null, 8, ["path"]),
            _createTextVNode(" Open editor ")
          ]))
        : _createCommentVNode("", true),
      (_ctx.isContentAuthoringTier2FeatureEnabled)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "btn-3",
            "data-testing": "mcq-card-btn-view-link",
            to: {
          name: 'mcq',
          params: {
            productId: _ctx.uiContentStore.sectionToDisplay.productId,
            sectionId: _ctx.uiContentStore.sectionToDisplay.sectionId
          }
        }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SVGIcon, { path: _ctx.mdiPencil }, null, 8, ["path"]),
              _createTextVNode(" Open editor ")
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ])
  ]))
}