import { defineStore } from "pinia";
import API from "@/api";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import { useAssetsMetadataStore } from "@/stores/assets-metadata.store";
import {
  AllocatedValueToCreateForStore,
  AllocatedValueToDeleteForStore,
  AllowedValueToCreateForStore,
  AllowedValueToDeleteForStore,
  AllowedValueToUpdateForStore,
  DefinitionToCreateForStore,
  DefinitionToCreateResponse,
  DefinitionToDeleteForStore,
  DefinitionToImport,
  DefinitionToUpdateForStore,
  InstanceToUpdateForStore
} from "@/models/api/metadata.model";
import { AxiosResponse } from "axios";

export const useMetadataStore = defineStore("metadata", () => {
  const productsStore = useProductsStore();
  const contStrStore = useContentStructureStore();
  const assetsMetadataStore = useAssetsMetadataStore();

  const updateMetadataInstance = async (payload: InstanceToUpdateForStore) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    const data = await API.metadata.updateInstance(payload.metadataInstance);
    await productsStore.populateProductDetails(payload.productId);
    return data;
  };

  const createMetadataDefinition = async (
    payload: DefinitionToCreateForStore
  ): Promise<AxiosResponse<DefinitionToCreateResponse>> => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    const data = await API.metadata.createDefinition(payload.metadata);
    const product = await API.product.get(payload.productId);
    productsStore.setProductDetails(product);
    contStrStore.setContentStructure(product);
    return data;
  };
  const updateMetadataDefinition = async (
    payload: DefinitionToUpdateForStore
  ) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    await API.metadata.updateDefinition(payload.metadata);
    await productsStore.populateProductDetails(payload.productId);
  };
  const deleteMetadataDefinition = async (
    payload: DefinitionToDeleteForStore
  ): Promise<AxiosResponse> => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    const data = await API.metadata.removeDefinition(payload.id);
    await productsStore.populateProductDetails(payload.productId);
    return data;
  };
  const importMetadataDefinitions = (payload: DefinitionToImport) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    assetsMetadataStore.isAssetsMetadataUpdating = true;
    return API.metadata.importDefinitions(payload).then(async () => {
      await Promise.all([
        assetsMetadataStore.populateAssetsMetadata(payload.to_section_type_id),
        productsStore.populateProductDetails(payload.product_id)
      ]);
    });
  };

  const createMetadataAllowedValues = (
    payload: AllowedValueToCreateForStore
  ) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.metadata.createAllowedValues(payload.values).then(() => {
      return productsStore.populateProductDetails(payload.productId);
    });
  };
  const updateMetadataAllowedValues = async (
    payload: AllowedValueToUpdateForStore
  ) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    await API.metadata.updateAllowedValues(payload.value);
    await productsStore.populateProductDetails(payload.productId);
  };
  const deleteMetadataAllowedValues = async (
    payload: AllowedValueToDeleteForStore
  ) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    await API.metadata.removeAllowedValues(payload.id);
    await productsStore.populateProductDetails(payload.productId);
  };

  const createMetadataAllocatedValues = async (
    payload: AllocatedValueToCreateForStore
  ) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.metadata.createAllocatedValues(payload.value).finally(() => {
      return productsStore.populateProductDetails(payload.productId);
    });
  };
  const deleteMetadataAllocatedValues = async (
    payload: AllocatedValueToDeleteForStore
  ) => {
    productsStore.areProductDetailsUpdating = true;
    contStrStore.isContentStructureUpdating = true;
    return API.metadata.removeAllocatedValues(payload.id).finally(() => {
      return productsStore.populateProductDetails(payload.productId);
    });
  };

  return {
    updateMetadataInstance,
    createMetadataDefinition,
    updateMetadataDefinition,
    deleteMetadataDefinition,
    importMetadataDefinitions,
    createMetadataAllowedValues,
    updateMetadataAllowedValues,
    deleteMetadataAllowedValues,
    createMetadataAllocatedValues,
    deleteMetadataAllocatedValues
  };
});
