import { defineStore } from "pinia";
import API from "@/api";
import { ref } from "vue";
import { ProductItem, ProductSummaryItem } from "@/models/product.model";
import {
  CreateProductResponse,
  ProductFeatureToUpdate,
  ProductImage,
  ProductToCreate,
  ProductToUpdate
} from "@/models/api/product.model";
import { AxiosResponse } from "axios";
import { useContentStructureStore } from "@/stores/content-structure.store";

export const useProductsStore = defineStore("products", () => {
  const contStrStore = useContentStructureStore();

  const isProductSummaryLoading = ref<boolean>(true);
  const isProductSummaryUpdating = ref<boolean>(false);
  const productSummary = ref<ProductSummaryItem[] | null>(null);
  const populateProductSummary = () => {
    return API.product.getAll().then((response: ProductSummaryItem[]) => {
      isProductSummaryLoading.value = false;
      isProductSummaryUpdating.value = false;
      productSummary.value = response;
    });
  };

  const areProductDetailsLoading = ref<boolean>(true);
  const areProductDetailsUpdating = ref<boolean>(false);
  const productDetails = ref<ProductItem[]>([]);
  const clearProductDetails = (productId: number) => {
    productDetails.value = productDetails.value.filter((item: ProductItem) => {
      return item.id !== productId;
    });
    areProductDetailsLoading.value = false;
    areProductDetailsUpdating.value = false;
  };
  const setProductDetails = (product: ProductItem) => {
    productDetails.value = productDetails.value.filter(
      (item: ProductItem): boolean => {
        return item.id !== product.id;
      }
    );
    productDetails.value.push(product);
    areProductDetailsLoading.value = false;
    areProductDetailsUpdating.value = false;
    contStrStore.setContentStructure(product);
  };
  const populateProductDetails = (productId: number) => {
    return API.product.get(productId).then((response: ProductItem) => {
      setProductDetails(response);
    });
  };

  const createProduct = (
    payload: ProductToCreate,
    shouldPopulateStore = true
  ) => {
    isProductSummaryUpdating.value = true;
    areProductDetailsUpdating.value = true;
    contStrStore.isContentStructureUpdating = true;
    return API.product
      .create(payload)
      .then(async (response: AxiosResponse<CreateProductResponse>) => {
        if (shouldPopulateStore) {
          await Promise.all([
            populateProductSummary(),
            populateProductDetails(response.data.data.product_id)
          ]);
        }
        return response;
      });
  };
  const updateProduct = (payload: ProductToUpdate) => {
    isProductSummaryUpdating.value = true;
    areProductDetailsUpdating.value = true;
    contStrStore.isContentStructureUpdating = true;
    return API.product.update(payload).then(async () => {
      await Promise.all([
        populateProductSummary(),
        populateProductDetails(payload.product_id)
      ]);
    });
  };
  const deleteProduct = (pId: number) => {
    isProductSummaryUpdating.value = true;
    areProductDetailsUpdating.value = true;
    contStrStore.isContentStructureUpdating = true;
    return API.product.remove(pId).then(async () => {
      await populateProductSummary();
      clearProductDetails(pId);
      contStrStore.clearContentStructure(pId);
    });
  };

  const addProductImage = (payload: ProductImage) => {
    isProductSummaryUpdating.value = true;
    areProductDetailsUpdating.value = true;
    contStrStore.isContentStructureUpdating = true;
    return API.product.addImage(payload.formData).then(async () => {
      await Promise.all([
        populateProductSummary(),
        populateProductDetails(payload.product_id)
      ]);
    });
  };

  const updateProductFeature = (payload: ProductFeatureToUpdate) => {
    isProductSummaryUpdating.value = true;
    areProductDetailsUpdating.value = true;
    return API.product.updateFeature(payload).then(() => {
      return populateProductDetails(payload.product_id);
    });
  };

  return {
    isProductSummaryLoading,
    isProductSummaryUpdating,
    productSummary,
    populateProductSummary,
    areProductDetailsLoading,
    areProductDetailsUpdating,
    productDetails,
    setProductDetails,
    clearProductDetails,
    populateProductDetails,
    createProduct,
    updateProduct,
    deleteProduct,
    addProductImage,
    updateProductFeature
  };
});
