import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIContentStore } from "@/stores/ui-content.store";
import { useProductsStore } from "@/stores/products.store";
import { useContentStructureStore } from "@/stores/content-structure.store";
import { computed, onMounted, watch } from "vue";
import { ProductItem } from "@/models/product.model";
import { useRoute } from "vue-router";
import {
  ContentStructureSection,
  GeneratedContentStructure
} from "@/models/content-structure.model";
import * as xApiHelper from "@/utils/product/x-api-helper";
import { TaskDisplay } from "@/models/task.model";
import { useTasksManager } from "@/composables/tasks-manager";
import { orderDisplaySectionByDeadline } from "@/utils/tasks-helpers";

export const useProductsManager = () => {
  const uiGeneralStore = useUIGeneralStore();
  const uiContentStore = useUIContentStore();
  const productsStore = useProductsStore();
  const contStrStore = useContentStructureStore();

  const route = useRoute();

  const isLoading = computed<boolean>(() => {
    return productsStore.areProductDetailsLoading;
  });
  const isUpdating = computed<boolean>(() => {
    return (
      productsStore.areProductDetailsUpdating ||
      contStrStore.isContentStructureUpdating
    );
  });

  const product = computed<ProductItem | undefined>(() => {
    if (isLoading.value || !route.params.productId) {
      return undefined;
    }
    return productsStore.productDetails?.find((e: ProductItem) => {
      return e.id === Number(route.params.productId);
    });
  });
  const structure = computed<GeneratedContentStructure | undefined>(() => {
    if (contStrStore.isContentStructureLoading) {
      return undefined;
    }
    return contStrStore.contentStructure.find(
      (e: GeneratedContentStructure) => {
        return e.product_id === Number(route.params.productId);
      }
    );
  });
  const rootSection = computed<ContentStructureSection | null>(() => {
    if (structure.value && structure.value.rootSection) {
      return structure.value.rootSection;
    }
    return null;
  });

  const isContentVisible = computed<boolean>(() => {
    return !isLoading.value && !!product.value;
  });

  onMounted(() => {
    if (product.value && product.value.name) {
      uiGeneralStore.setPageHeading(product.value.name);
    }
  });
  watch(
    () => product.value,
    newVal => {
      if (newVal && newVal.name) {
        uiGeneralStore.setPageHeading(newVal.name);
      }
    }
  );

  const checkXAPI = async (product: ProductItem): Promise<void> => {
    const allErrors = await xApiHelper.validateBuildXAPI(product);
    uiContentStore.setXAPIErrors({
      productId: product.id,
      ...allErrors
    });
  };

  const { getTasksByProduct } = useTasksManager();
  const allTasksInAProduct = computed<TaskDisplay[]>(() => {
    if (!product.value) {
      return [];
    }
    const allTasks = getTasksByProduct(product.value.id);
    return allTasks.sort(orderDisplaySectionByDeadline);
  });

  return {
    isLoading,
    isUpdating,
    product,
    structure,
    rootSection,
    isContentVisible,
    checkXAPI,
    allTasksInAProduct
  };
};
