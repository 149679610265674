<template>
  <Collapse>
    <template v-slot:title>Curriculum Tags</template>
    <template v-slot:content>
      <b-field v-if="showConceptsSelector" label="Concepts">
        <b-autocomplete
          v-model="typedConcept"
          :data="filteredConceptsByTopics"
          @select="processSelectedConcept"
          placeholder="e.g. Adding fractions"
          group-field="topicName"
          group-options="conceptsNames"
          :confirm-keys="['Enter']"
          keep-first
          open-on-focus
          clearable
          clear-on-select
        >
        </b-autocomplete>
      </b-field>
      <div v-if="!showConceptsSelector">
        {{ message }}
      </div>
      <TagsList
        v-if="showTags"
        :tags="sectionTagsWithNames"
        @removeTag="removeConceptFromSection"
        :can-edit-content="canEditContent"
      />
    </template>
  </Collapse>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import API from "@/api/index";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import TagsList from "@/components/right-sidebars/content-sidebar/curriculum/TagsList.vue";
import {
  ConceptJson,
  ConceptsByTopic,
  TopicJson
} from "@/models/curriculum.model";
import { SectionConcepts } from "@/models/product-section.model";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";

interface Data {
  typedConcept: string;
}

export default defineComponent({
  name: "CurriculumTags",
  components: {
    TagsList,
    Collapse
  },
  props: {
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    },
    productCurriculumId: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    topicsFromXml: {
      type: Array as PropType<TopicJson[]>,
      default: () => []
    },
    conceptsFromXml: {
      type: Array as PropType<ConceptJson[]>,
      default: () => []
    },
    xmlParsedSuccessfully: {
      type: Boolean,
      default: false
    },
    canEditContent: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data(): Data {
    return {
      typedConcept: ""
    };
  },
  computed: {
    ...mapStores(useUIGeneralStore),
    message(): string {
      if (this.isLoading) {
        return "Loading...";
      }
      if (!this.xmlParsedSuccessfully) {
        return "Curriculum file is invalid";
      }
      if (!this.conceptsByTopicsFromXml.length) {
        return "Curriculum file has no topics or concepts";
      }
      if (this.sectionHaveTagsButDoesntHaveTagsFromSelectedCurriculum) {
        return `This section doesn't have tags from selected curriculum, but have tags from another curriculum.`;
      }
      return "";
    },
    showTags(): boolean {
      return (
        !this.isLoading &&
        this.xmlParsedSuccessfully &&
        this.conceptsByTopicsFromXml.length > 0 &&
        !!this.sectionTagsWithNames &&
        (this.sectionTagsWithNames.length > 0 ||
          (this.sectionTagsWithNames.length === 0 &&
            this.uiGeneralStore.isEditModeEnabled))
      );
    },
    showConceptsSelector(): boolean {
      return (
        !this.isLoading &&
        this.xmlParsedSuccessfully &&
        !!this.conceptsByTopicsFromXml.length &&
        this.canEditContent
      );
    },
    conceptsByTopicsFromXml(): ConceptsByTopic[] {
      const conceptsByTopics: ConceptsByTopic[] = [];
      this.topicsFromXml.forEach((topic: TopicJson) => {
        const item: ConceptsByTopic = {
          topicName: topic.name,
          topicFullData: topic,
          conceptsNames: [],
          conceptsFullData: []
        };
        this.conceptsFromXml.forEach((concept: ConceptJson) => {
          if (topic.guid === concept.parentGuid) {
            item.conceptsNames.push(concept.name);
            item.conceptsFullData.push(concept);
          }
        });
        conceptsByTopics.push(item);
      });
      return conceptsByTopics;
    },
    filteredConceptsByTopics(): ConceptsByTopic[] {
      if (!this.typedConcept.length) {
        return this.conceptsByTopicsFromXml;
      }
      const filteredConceptsByTopics: ConceptsByTopic[] = [];
      this.conceptsByTopicsFromXml.forEach((element: ConceptsByTopic) => {
        const conceptsNames = element.conceptsNames.filter(
          (conceptName: string) => {
            return (
              conceptName
                .toLowerCase()
                .indexOf(this.typedConcept.toLowerCase()) >= 0
            );
          }
        );
        if (conceptsNames.length) {
          filteredConceptsByTopics.push({
            topicName: element.topicName,
            topicFullData: element.topicFullData,
            conceptsNames: conceptsNames,
            conceptsFullData: element.conceptsFullData
          });
        }
      });
      return filteredConceptsByTopics;
    },
    sectionHaveTagsButDoesntHaveTagsFromSelectedCurriculum(): boolean {
      return (
        this.section.concepts.length > 0 &&
        this.sectionTagsFromSelectedCurriculum.length === 0
      );
    },
    sectionTagsFromSelectedCurriculum(): SectionConcepts[] {
      if (!this.section.hasOwnProperty("concepts")) {
        return [];
      }
      const conceptsFromXmlGuids = this.conceptsFromXml.map(
        (conceptXml: ConceptJson) => conceptXml.guid
      );
      return this.section.concepts.filter((sectionConcept: SectionConcepts) => {
        return conceptsFromXmlGuids.includes(sectionConcept.concept_guid);
      });
    },
    sectionTagsWithNames(): SectionConcepts[] {
      return this.sectionTagsFromSelectedCurriculum.map(
        (sectionConcept: SectionConcepts) => {
          const conceptName = this.conceptsFromXml.find(
            (conceptFromXml: ConceptJson) => {
              return conceptFromXml.guid === sectionConcept.concept_guid;
            }
          );
          if (conceptName) {
            sectionConcept.name = conceptName.name;
          }
          return sectionConcept;
        }
      );
    }
  },
  methods: {
    processSelectedConcept(conceptName: string): void {
      const selectedConcept = this.getConceptByName(conceptName);
      const selectedSectionConceptsGuids = this.section.concepts.map(
        (concept: SectionConcepts) => concept.concept_guid
      );
      if (
        selectedConcept &&
        !selectedSectionConceptsGuids.includes(selectedConcept.guid)
      ) {
        this.addConceptToSection(selectedConcept);
      }
    },
    getConceptByName(conceptName: string): ConceptJson | undefined {
      return this.conceptsFromXml.find((concept: ConceptJson) => {
        return concept.name === conceptName;
      });
    },
    addConceptToSection(concept: ConceptJson): Promise<void> {
      return API.content
        .addConceptToContent({
          concept_guid: concept.guid,
          section_id: this.section.id
        })
        .then((response): void => {
          const concept = response.data.data;
          this.section.concepts.push(concept);
        });
    },
    removeConceptFromSection(conceptSectionId: number): Promise<void> {
      return API.content.removeConceptFromContent(conceptSectionId).then(() => {
        const indexOfDeletedConcept = this.section.concepts.findIndex(
          (conceptSection: SectionConcepts): boolean => {
            return conceptSection.id === conceptSectionId;
          }
        );
        this.section.concepts.splice(indexOfDeletedConcept, 1);
      });
    }
  }
});
</script>
