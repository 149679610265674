// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { ProductSummaryItem } from "@/models/product.model";
import { getAllowedProducts } from "@/utils/filter-products-helpers";
import { mapStores } from "pinia";
import { useCurriculumsStore } from "@/stores/curriculums.store";
import { useOrganizationStore } from "@/stores/organization.store";
import { useUsersStore } from "@/stores/users.store";
import { useProgrammesStore } from "@/stores/programmes.store";
import { useProductsStore } from "@/stores/products.store";
import { useFilesStore } from "@/stores/files.store";

const PopulateStoreMixin = {
  computed: {
    ...mapStores(
      useCurriculumsStore,
      useOrganizationStore,
      useUsersStore,
      useProgrammesStore,
      useProductsStore,
      useFilesStore
    )
  },
  methods: {
    async populateOrganizationData(): void {
      await Promise.all([
        this.organizationStore.populateOrganization(),
        this.usersStore.populateUsers()
      ]);
    },
    populateStoreWithGeneralData(): void {
      this.populateProductDetails();
      this.programmesStore.populateProgrammes();
      this.curriculumsStore.populateCurriculums();
      this.filesStore.populateFiles();
    },

    async populateProductDetails(): Promise<void> {
      await this.productsStore.populateProductSummary();
      if (
        this.usersStore.currentUser.id &&
        this.productsStore.productSummary &&
        this.productsStore.productSummary.length > 0
      ) {
        let allowedProducts = getAllowedProducts(
          this.productsStore.productSummary,
          this.usersStore.currentUser.permissions
        );
        if (this.$route.params.productId) {
          allowedProducts = allowedProducts.filter(
            (product: ProductSummaryItem) => {
              return product.id !== Number(this.$route.params.productId);
            }
          );
          await this.productsStore.populateProductDetails(
            Number(this.$route.params.productId)
          );
        }
        allowedProducts.forEach((product: ProductSummaryItem): void => {
          this.productsStore.populateProductDetails(product.id);
        });
      }
    }
  }
};
export default PopulateStoreMixin;
